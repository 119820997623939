import documentBrown from '../images/document-brown.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'
import docImage from '../images/icon/document-black.png'
import houseImage from '../images/icon/house-image.png'
import buildingImage from '../images/icon/building-black.png'
import carImage from '../images/icon/car-image.png'
import stampBlackImage from '../images/icon/stamp-black.png'
import coinImage from '../images/icon/coin-image.png'
import hammerImage from '../images/icon/hammer.png'
import courtImage from '../images/icon/court-black.png'
import closeImage from '../images/icon/close-cross.png'

const vocabularyPowerOfAttorney = {
  powersOfAttorney: [
    'доверенности',
    'Powers of Attorney',
    '授权书',
    'Poderes notariales',
  ],
  desc1: [
    'Чтобы провести из-за рубежа сделку с недвижимостью, решить вопросы ЖКХ или строительства дома, потребуется доверенность — на человека в России, который и будет заниматься этим вопросом.',
    'To conduct a real estate transaction from abroad, resolve housing and utilities issues, or build a house, a Power of Attorney will be required. This document will authorize a person in Russia to handle these matters on your behalf.',
    '若需在海外进行房地产交易、处理住房和公用设施问题或建造房屋，则需要授权书，以便授权在俄罗斯的人为您处理这些事务。',
    'Para realizar una transacción inmobiliaria desde el extranjero, resolver asuntos de vivienda y servicios públicos o construir una casa, se requerirá un poder notarial para autorizar a una persona en Rusia a manejar estos asuntos en su nombre.',
  ],
  desc2: [
    'Для того, чтобы сделать такую доверенность, не обязательно возвращаться в РФ: документ можно получить в ближайшем к вам Российском консульстве. Однако сотрудники консульства не занимаются составлением текстов документов, только заверяют заранее подготовленные в специальном формате проекты документов.',
    "To arrange a Power of Attorney, it's not necessary to return to Russia: the document can be obtained at the nearest Russian consulate. However, consulate staff do not draft documents; they only certify prepared draft documents in a special format.",
    '办理这种授权书无需返回俄罗斯：文件可以在离您最近的俄罗斯领事馆获得。然而，领事馆的工作人员不撰写文件；他们只认证已准备好的格式文件。',
    'Para obtener un poder notarial, no es necesario regresar a Rusia: el documento se puede obtener en el consulado ruso más cercano. Sin embargo, el personal del consulado no redacta documentos; solo certifica borradores preparados en un formato especial.',
  ],
  desc3: [
    'Мы поможем вам подготовить проект доверенности, проконсультируем по поводу записи на приём в консульство или запишем вас сами, и разместим готовый проект документа в нужном формате на консульском портале.',
    'We will assist you in preparing a draft of the Power of Attorney, provide consultation regarding scheduling an appointment at the consulate, or schedule an appointment for you. Additionally, we will upload the finalized draft document in the required format to the consulate portal.',
    '我们将协助您准备授权书草案，提供关于预约领事馆的咨询，或为您安排预约。此外，我们还将在领事馆门户网站上上传格式要求的最终草案文件。',
    'Le ayudaremos a preparar un borrador del poder notarial, le asesoraremos para programar una cita en el consulado o le haremos la cita. Además, subiremos el borrador del documento finalizado en el formato requerido al portal del consulado.',
  ],
  necessaryDocList: [
    'Какие документы потребуются?',
    'What documents will be required?',
    '需要哪些文件？',
    '¿Qué documentos se necesitarán?',
  ],
  baseDocList: [
    'Базовый набор документов зависит от того, какой правовой статус имеет доверитель (от чьего имени оформляется доверенность):',
    'The basic set of documents depends on the legal status of the principal (on whose behalf the Power of Attorney is being issued):',
    '所需文件的基本套件取决于委托人的法律地位（即授权书以谁的名义签发）：',
    'El conjunto básico de documentos depende del estado legal del principal (en cuyo nombre se emite el poder):',
  ],

  userTypes: [
    {
      userName: [
        'Физическое лицо старше 18 лет',
        'Individual over 18 years old',
        '年满18岁的个人',
        'Persona mayor de 18 años',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ',
          'Russian internal passport and/or Russian passport',
          '俄罗斯内务护照和/或俄罗斯护照',
          'Pasaporte interno de Rusia y/o pasaporte ruso',
        ],
        [
          'Адрес места жительства за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence abroad in Russian (if there is no registration in Russia)',
          '在俄语中注明的国外居住地址（如果没有俄罗斯注册）',
          'Dirección de residencia en el extranjero en ruso (si no hay registro en Rusia)',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized person, including the registration (or representative - to whom entrusted)',
          '授权人或代表的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del autorizado, incluida la dirección de registro (o representante de confianza)',
        ],
      ],
    },
    {
      userName: [
        'По доверенности от другого лица',
        'The Power of Attorney is issued by another person',
        '由他人签发的委托书',
        'Por poder de otra persona',
      ],
    },
    {
      userName: ['Ребёнок младше 14 лет', 'The child is under 14 years old', '14岁以下的儿童', 'Niño menor de 14 años'],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
          'Internal passport of the Russian Federation and/or Russian international passport of the parent (or legal representative)',
          '父母（或法定代表人）的俄罗斯内务护照和/或国际护照',
          'Pasaporte interno de la Federación Rusa y/o pasaporte internacional ruso del padre o tutor legal',
        ],
        [
          'Свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
          'Birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian)',
          '孩子的出生证明（如果是外国文件，必须有公证的俄文翻译）',
          'Certificado de nacimiento del niño (si es un documento extranjero, debe contar con apostilla y traducción notarial al ruso)',
        ],
        [
          'Загранпаспорт РФ ребенка',
          'Foreign passport of the child (if applicable)',
          '孩子的外国护照（如适用）',
          'Pasaporte extranjero del niño (si aplica)',
        ],
        [
          'Адрес места жительства родителя и ребенка за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence of the parent and child abroad in Russian (if there is no registration in Russia)',
          '父母和孩子的国外居住地址（如未在俄罗斯注册）',
          'Dirección de residencia en el extranjero del padre y el niño en ruso (si no hay registro en Rusia)',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized representative, including registration (or representative - the person trusted)',
          '代表或授权人的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del representante autorizado, incluida la dirección de registro',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка при оформлении доверенности не требуется.',
          'Child appearance is not required',
          '办理授权时无需孩子出席',
          'No se requiere la presencia del niño',
        ],
      ],
    },
    {
      userName: [
        'Ребёнок возрастом от 14 до 18 лет',
        'The child is over 14 years old',
        '年龄在14至18岁的儿童',
        'Niño de 14 a 18 años',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ родителя (или законного представителя)',
          'Internal passport of the Russian Federation and/or Russian international passport of the parent (or legal representative)',
          '父母（或法定代表人）的俄罗斯内务护照和/或国际护照',
          'Pasaporte interno de la Federación Rusa y/o pasaporte internacional ruso del padre o tutor legal',
        ],
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ ребенка',
          'Russian domestic passport and/or Russian international passport of the child',
          '孩子的俄罗斯国内护照和/或国际护照',
          'Pasaporte doméstico y/o internacional ruso del niño',
        ],
        [
          'Свидетельство о рождении ребенка (если документ иностранного образца, то он должен быть с апостилем и нотариально заверенным переводом на русский язык)',
          'Birth certificate of the child (if the document is of foreign origin, it must have an apostille and a notarized translation into Russian)',
          '孩子的出生证明（如果是外国文件，必须有公证的俄文翻译）',
          'Certificado de nacimiento del niño (si es un documento extranjero, debe contar con apostilla y traducción notarial al ruso)',
        ],
        [
          'Загранпаспорт РФ ребенка',
          'Foreign passport of the child (if applicable)',
          '孩子的外国护照（如适用）',
          'Pasaporte extranjero del niño (si aplica)',
        ],
        [
          'Адрес места жительства родителя и ребенка за рубежом на русском языке (если отсутствует прописка в России)',
          'Address of residence of the parent and child abroad in Russian (if there is no registration in Russia)',
          '父母和孩子的国外居住地址（如未在俄罗斯注册）',
          'Dirección de residencia en el extranjero del padre y el niño en ruso (si no hay registro en Rusia)',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian internal passport of the authorized representative, including registration (or representative - the person trusted)',
          '代表或授权人的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del representante autorizado, incluida la dirección de registro',
        ],
      ],
      comments: [
        [
          'Присутствие ребенка и родителя обязательно.',
          'Child and parent appearance is required',
          '孩子和父母的出席是必须的',
          'La presencia del niño y del padre es obligatoria',
        ],
      ],
    },
    {
      userName: ['Индивидуальный предприниматель', 'Self contractor', '个体经营者', 'Contratista independiente'],
      desc: [
        [
          'Выписка из ЕГРИП',
          'Extract from the Unified State Register of Individual Entrepreneurs (EGRIP)',
          '个人企业家统一国家注册的摘录',
          'Extracto del Registro Estatal Unificado de Empresarios Individuales (EGRIP)',
        ],
        [
          'Свидетельство ОГРН, ИНН',
          'Certificate of State Registration Number (OGRN), Taxpayer Identification Number (INN)',
          '国家注册号（OGRN）、纳税人识别号（INN）证书',
          'Certificado del Número de Registro Estatal (OGRN), Número de Identificación Fiscal (INN)',
        ],
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ доверителя',
          'Russian domestic passport and/or Russian international passport of the attorney',
          '授权人的俄罗斯国内护照和/或国际护照',
          'Pasaporte ruso interno y/o pasaporte internacional del autorizado',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)',
          '授权人或代表的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del autorizado, incluyendo el lugar de residencia',
        ],
      ],
    },
    {
      userName: ['Юридическое лицо', 'Legal entity', '法人实体', 'Entidad jurídica'],
      desc: [
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if any',
          '组织章程及其修改（如有）',
          'Estatuto de la organización y sus enmiendas, si las hay',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities (EGRUL)',
          '法人实体统一国家注册的摘录',
          'Extracto del Registro Estatal Unificado de Entidades Jurídicas (EGRUL)',
        ],
        [
          'Свидетельство ОГРН, ИНН, КПП',
          'Certificate of State Registration Number (OGRN), Taxpayer Identification Number (INN), Tax Registration Reason Code (KPP)',
          '国家注册号（OGRN）、纳税人识别号（INN）、税务登记原因代码（KPP）证书',
          'Certificado del Número de Registro Estatal (OGRN), Número de Identificación Fiscal (INN), Código de Registro Fiscal (KPP)',
        ],
        [
          'Решение о назначении директора',
          'Decision on the appointment of the director',
          '关于任命董事的决议',
          'Decisión sobre el nombramiento del director',
        ],
        [
          'Приказ о назначении директора',
          'Order on the appointment of the director',
          '董事任命令',
          'Orden de nombramiento del director',
        ],
        ['Паспортные данные директора', 'Passport details of the director', '董事的护照信息', 'Datos del pasaporte del director'],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)',
          '授权人或代表的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del autorizado, incluida la dirección de registro',
        ],
      ],
    },
    {
      userName: [
        'Учредитель (участник, акционер)',
        'Founder (participant, shareholder)',
        '创始人（参与者，股东）',
        'Fundador (participante, accionista)',
      ],
      desc: [
        [
          'Внутренний паспорт РФ и/или загранпаспорт РФ учредителя (участника, акционера)',
          'Russian domestic passport and/or foreign passport of the founder (participant, shareholder)',
          '创始人（参与者、股东）的俄罗斯国内护照和/或外国护照',
          'Pasaporte doméstico ruso y/o pasaporte extranjero del fundador (participante, accionista)',
        ],
        [
          'СНИЛС (если имеется)',
          'Individual insurance account number (SNILS) (if available)',
          '个人保险账户编号（如有）',
          'Número de cuenta de seguro individual (SNILS) (si está disponible)',
        ],
        [
          'Устав Организации и его изменения, если имели место быть',
          'Charter of the Organization and its amendments, if any',
          '组织章程及其修改（如有）',
          'Estatuto de la organización y sus enmiendas, si las hay',
        ],
        [
          'Выписка из ЕГРЮЛ',
          'Extract from the Unified State Register of Legal Entities (EGRUL)',
          '法人实体统一国家注册的摘录',
          'Extracto del Registro Estatal Unificado de Entidades Jurídicas (EGRUL)',
        ],
        [
          'Паспортные данные либо копия внутреннего паспорта РФ поверенного, включая прописку (или представителя - того, кому доверяют)',
          'Passport details or a copy of the Russian domestic passport of the attorney, including the place of residence (or representative - the person who is entrusted)',
          '授权人或代表的俄罗斯护照信息或副本，包括注册信息',
          'Datos del pasaporte o copia del pasaporte interno ruso del autorizado, incluida la dirección de registro',
        ],
      ],
    },
    {
      userName: [
        'Иностранный гражданин',
        'Foreign national citizen',
        '外国公民',
        'Ciudadano extranjero',
      ],
    },
  ]


,
infoPowerOfAttorney1: [
  'Для оформления нотариальных документов приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
  'For processing notarial documents, priority is given to the domestic passport. In case the domestic passport has not been obtained or has expired, a valid foreign passport will be required. If both passports are available, it is necessary to bring both passports to the consulate.',
  '在处理公证文件时，优先考虑国内护照。如果国内护照未获得或已过期，则需要有效的外国护照。如果同时拥有这两本护照，则必须将两本护照都带到领事馆。',
  'Para el procesamiento de documentos notariales, se da prioridad al pasaporte nacional. En caso de que no se haya obtenido el pasaporte nacional o esté caducado, será necesario un pasaporte extranjero válido. Si se disponen de ambos pasaportes, es necesario llevar ambos al consulado.',
],
infoPowerOfAttorney2: [
  'Присутствие представителя (или поверенного — того, кому доверяют) для оформления доверенности не требуется.',
  'The presence of a representative (or attorney - the one who is granted right) is not required to execute a Power of Attorney.',
  '代表（或授权人——被授予权利的人）在办理委托书时不需要出席。',
  'No se requiere la presencia de un representante (o abogado - la persona a la que se le otorgan derechos) para ejecutar un poder notarial.',
],
typesOfPowersOfAttorney: [
  'виды доверенностей',
  'TYPES OF POWERS OF ATTORNEY',
  '类型的委托书',
  'TIPOS DE PODERES NOTARIALES',
],
orderOfRegistration: [
  'Порядок Оформления',
  'Steps to Obtain',
  '注册流程',
  'Pasos para obtener',
],
orderDescription: [
  [
      {
          headerText: 'Оформите заявку',
          cardText:
              'Опишите, что должно содержаться в доверенности. Вы можете направить нам требуемые документы как одновременно с заявкой, так и напрямую юристу после консультации с ним.',
          cardImage: checkBoxImage,
      },
      {
          headerText: 'Подготовка текста документа',
          cardText:
              'Наш юрист свяжется с вами, обсудит детали, подготовит проект доверенности и разместит его на портале КД МИД России. Вы получите 6-значный код, который необходимо будет взять с собой в консульство. Срок выполнения услуги — 2 рабочих дня с момента оплаты.',
          cardImage: documentBrown,
      },
      {
          headerText: 'Запись на прием в консульство',
          cardText:
              'Запишитесь в консульство на приём самостоятельно или с нашей помощью (согласно выбранному тарифу)',
          cardImage: calendarImage,
      },
      {
          headerText: 'Получение готового документа',
          cardText:
              'В день записи приходите в консульство с паспортом и шестизначным кодом для заверения подготовленной доверенности',
          cardImage: stampImage,
      },
  ],
  [
      {
          headerText: 'Submit an Application',
          cardText:
              'Describe what should be included in the Power of Attorney. You can send us the required documents either simultaneously with the application or directly to the lawyer after consulting with him/her.',
          cardImage: checkBoxImage,
      },
      {
          headerText: 'Preparation of the Document Text',
          cardText:
              'Our lawyer will contact you, discuss the details, prepare a draft Power of Attorney, and upload it to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia. You will receive a 6-digit code, which you will have to take with you to the consulate. The service completion time is 2 working days from the moment of payment.',
          cardImage: documentBrown,
      },
      {
          headerText: 'Scheduling an appointment at the consulate',
          cardText:
              'Schedule an appointment at the consulate yourself or with our assistance (according to the selected tariff)',
          cardImage: calendarImage,
      },
      {
          headerText: 'Receipt of the Ready Document',
          cardText:
              'On the day of the appointment, come to the consulate with your passport and the six-digit code to have the prepared Power of Attorney certified',
          cardImage: stampImage,
      },
  ],
  [
      {
          headerText: '提交申请',
          cardText:
              '描述委托书中应包含的内容。您可以在申请时或与律师咨询后直接向我们发送所需的文件。',
          cardImage: checkBoxImage,
      },
      {
          headerText: '文件文本准备',
          cardText:
              '我们的律师将与您联系，讨论细节，准备委托书草案，并将其上传至俄罗斯外交部领事部门的门户网站。您将收到一个6位数字的代码，您必须将其带到领事馆。服务完成时间为付款后2个工作日。',
          cardImage: documentBrown,
      },
      {
          headerText: '在领事馆预约',
          cardText:
              '您可以自己或在我们协助下预约领事馆（根据所选的费用）',
          cardImage: calendarImage,
      },
      {
          headerText: '接收准备好的文件',
          cardText:
              '在预约日，携带护照和六位数字代码前往领事馆，以便对准备好的委托书进行认证。',
          cardImage: stampImage,
      },
  ],
  [
      {
          headerText: 'Enviar una solicitud',
          cardText:
              'Describa qué debe incluirse en el Poder Notarial. Puede enviarnos los documentos requeridos ya sea simultáneamente con la solicitud o directamente al abogado después de consultarle.',
          cardImage: checkBoxImage,
      },
      {
          headerText: 'Preparación del texto del documento',
          cardText:
              'Nuestro abogado se pondrá en contacto con usted, discutirá los detalles, preparará un borrador del Poder Notarial y lo subirá al portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia. Recibirá un código de 6 dígitos, que deberá llevar con usted al consulado. El tiempo de finalización del servicio es de 2 días hábiles desde el momento del pago.',
          cardImage: documentBrown,
      },
      {
          headerText: 'Agendar una cita en el consulado',
          cardText:
              'Programe una cita en el consulado usted mismo o con nuestra ayuda (de acuerdo con la tarifa seleccionada)',
          cardImage: calendarImage,
      },
      {
          headerText: 'Recepción del documento listo',
          cardText:
              'El día de la cita, venga al consulado con su pasaporte y el código de seis dígitos para certificar el Poder Notarial preparado.',
          cardImage: stampImage,
      },
  ],
],

prices: [
  'ТАРИФЫ И ЦЕНЫ',
  'Tariffs and Prices',
  '价格和费用',
  'Tarifas y precios',
],

priceWithoutAppointment: [
  {
      header: 'Без записи в консульство',
      list: [
          'подготовим проект доверенности',
          'разместим документ на портале КД МИД России',
          'проконсультируем по перечню документов, необходимых для заверения',
          'проконсультируем по вопросу самостоятельной записи на приём в консульство',
      ],
      value: '60',
  },

  {
      header: 'Without an appointment at the consulate',
      list: [
          'we will prepare a draft Power of Attorney',
          'we will upload the document to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
          'we will advise you on the list of documents required for certification',
          'we will advise on scheduling an appointment at the consulate on your own',
      ],
      value: '60',
  },
  
  {
      header: '无需预约领事馆',
      list: [
          '我们将准备委托书草案',
          '我们将把文件上传到俄罗斯外交部领事部门的门户网站',
          '我们将就认证所需文件清单提供咨询',
          '我们将就自行预约领事馆提供咨询',
      ],
      value: '60',
  },

  {
      header: 'Sin cita en el consulado',
      list: [
          'prepararemos un borrador del poder notarial',
          'subiremos el documento al portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia',
          'le asesoraremos sobre la lista de documentos requeridos para la certificación',
          'le asesoraremos sobre cómo programar una cita en el consulado por su cuenta',
      ],
      value: '60',
  },
],

priceWithAppointment: [
  {
      header: 'С записью в консульство',
      list: [
          'подготовим проект доверенности',
          'разместим документ на портале КД МИД России',
          'проконсультируем по перечню документов, необходимых для заверения',
          'запишем вас на приём в консульство',
      ],
      value: '80',
  },

  {
      header: 'With an appointment at the consulate',
      list: [
          'we will prepare a draft Power of Attorney',
          'we will upload the document to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
          'we will advise you on the list of documents required for certification',
          'we will schedule an appointment for you at the consulate',
      ],
      value: '80',
  },

  {
      header: '有领事馆预约',
      list: [
          '我们将准备委托书草案',
          '我们将把文件上传到俄罗斯外交部领事部门的门户网站',
          '我们将就认证所需文件清单提供咨询',
          '我们将为您在领事馆预约',
      ],
      value: '80',
  },

  {
      header: 'Con cita en el consulado',
      list: [
          'prepararemos un borrador del poder notarial',
          'subiremos el documento al portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia',
          'le asesoraremos sobre la lista de documentos requeridos para la certificación',
          'programaremos una cita para usted en el consulado',
      ],
      value: '80',
  },
],

paymentProcessing1: [
  'Оплатить услугу возможно как во время оформления заявки на сайте, так и после консультации с юристом.',
  'It is possible to pay for the service both during the application process on the website and after consulting with the lawyer.',
  '在网站申请过程中或在咨询律师后均可支付服务费用。',
  '可以在网站申请过程中或在咨询律师后支付服务费用。',
],

paymentProcessing2: [
  'Мы принимаем к оплате на сайте международные банковские карты, Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
  'We accept international bank cards, Apple Pay, and Google Pay for payment on the website. Payment with Russian bank cards, PayPal, or in cryptocurrency is not processed on the website but is possible upon request.',
  '我们接受国际银行卡、Apple Pay和Google Pay在网站上支付。网站上不接受俄罗斯银行卡、PayPal或加密货币的支付，但可应要求进行处理。',
  'Aceptamos tarjetas bancarias internacionales, Apple Pay y Google Pay para el pago en el sitio web. El pago con tarjetas bancarias rusas, PayPal o en criptomonedas no se procesa en el sitio web, pero es posible a pedido。',
],

faq: [
  'Часто задаваемые вопросы',
  'Frequently Asked Questions',
  '常见问题',
  'Preguntas Frecuentes',
],

  faqDesc: [
    [
      {
        question: 'Могу ли я оформить доверенность без моего личного присутствия?',
        answer: 'Нет, личное присутствие доверителя обязательно.',
      },
      {
        question: 'Что делать, если человек, на которого оформляется доверенность, находится в РФ?',
        answer: 'Ничего страшного, присутствие поверенного для оформления доверенности не требуется. Достаточно предоставить его паспортные данные и информацию о прописке.',
      },
      {
        question: 'Нужно ли брать с собой ребёнка на приём в консульство, если доверенность оформляется от его имени?',
        answer: 'Зависит от возраста ребёнка. Присутствие ребёнка до 14 лет не требуется. Дети в возрасте от 14 до 18 лет оформляют доверенности от своего имени, но с согласия одного из родителей, поэтому обязательно присутствие как ребёнка, так и родителя.',
      },
      {
        question: 'Какой паспорт нужно предъявить для оформления нотариального документа?',
        answer: 'Приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
      },
      {
        question: 'Я — иностранный гражданин. Могу ли оформить доверенность на гражданина РФ?',
        answer: 'Да, конечно, это возможно.',
      },
      {
        question: 'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        answer: 'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля).',
      },
      {
        question: 'На какой максимальный срок можно оформить доверенность?',
        answer: 'Вы вправе оформить доверенность с любым сроком действия. Вместе с тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
      },
      {
        question: 'Можно ли в доверенности указать несколько доверенных лиц?',
        answer: 'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
      },
    ],

    [
      {
        question: 'Can I arrange a Power of Attorney without my personal presence?',
        answer: "No, the principal's personal presence is mandatory.",
      },
      {
        question: 'What should I do if the person for whom the Power of Attorney is being arranged is in Russia?',
        answer: 'Nothing to worry about, the presence of the attorney to issue the Power of Attorney is not required. It is sufficient to provide their passport details and registration information.',
      },
      {
        question: 'Do I need to bring my child to the consulate appointment if the Power of Attorney is being arranged on their behalf?',
        answer: 'It depends on the age of the child. The presence of children under 14 years of age is not required. Children aged 14 to 18 arrange Powers of Attorney in their own name, but with the consent of one of the parents, so the presence of both the child and the parent is mandatory.',
      },
      {
        question: 'Which passport do I need to present to arrange a notarized document?',
        answer: 'Priority is given to the internal passport. If the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, both passports must be taken to the consulate appointment.',
      },
      {
        question: 'I am a foreign citizen. Can I arrange a Power of Attorney for a Russian citizen?',
        answer: 'Yes, of course, it is possible.',
      },
      {
        question: 'Is it possible to arrange a Power of Attorney at the Russian consulate for representation in Kazakhstan or Belarus?',
        answer: 'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney issued at the Russian Consulate is accepted by the government agencies and institutions of these countries without additional certification (apostille).',
      },
      {
        question: 'What is the maximum period for which a Power of Attorney can be issued?',
        answer: 'You can issue Powers of Attorney with any validity. At the same time, a specific period of validity must be specified; the certification of "indefinite" Powers of Attorney is not allowed.',
      },
      {
        question: 'Can multiple attorneys be specified in a Power of Attorney?',
        answer: 'Yes, Powers of Attorney can be issued on behalf of one or several persons, for one or several persons.',
      },
    ],

    [
      {
        question: '我可以在没有个人出席的情况下办理授权书吗？',
        answer: '不可以，委托人必须亲自到场。',
      },
      {
        question: '如果办理授权书的人在俄罗斯，我该怎么办？',
        answer: '不用担心，办理授权书不需要代理人的出席。只需提供他们的护照信息和注册信息即可。',
      },
      {
        question: '如果授权书是以孩子的名义办理，我需要带孩子去领事馆吗？',
        answer: '这取决于孩子的年龄。14岁以下的孩子不需要出席。14到18岁的孩子可以以自己的名义办理授权书，但需要其中一位父母的同意，因此必须有孩子和父母的出席。',
      },
      {
        question: '办理公证文件需要出示哪本护照？',
        answer: '优先使用内务护照。如果内务护照未发放或已过期，则需要有效的外国护照。如果两本护照都有，则必须带两本护照去领事馆。',
      },
      {
        question: '我是一名外国公民。可以为俄罗斯公民办理授权书吗？',
        answer: '是的，当然可以。',
      },
      {
        question: '在俄罗斯领事馆可以办理用于在哈萨克斯坦或白俄罗斯代表利益的授权书吗？',
        answer: '可以。根据俄罗斯、哈萨克斯坦、白俄罗斯、塔吉克斯坦、格鲁吉亚等国家参与的法律援助公约，在俄罗斯领事馆办理的授权书被这些国家的政府机构和单位接受，无需额外认证（公证）。',
      },
      {
        question: '授权书的最长有效期是多长？',
        answer: '您可以办理任何有效期的授权书。同时，必须指定具体的有效期；不允许认证“无限期”授权书。',
      },
      {
        question: '可以在授权书中指定多个受托人吗？',
        answer: '可以，授权书可以以一个或多个人的名义办理，给一个或多个受托人。',
      },
    ],

    [
      {
        question: '¿Puedo organizar un poder notarial sin mi presencia personal?',
        answer: 'No, la presencia personal del otorgante es obligatoria.',
      },
      {
        question: '¿Qué debo hacer si la persona para quien se está organizando el poder notarial está en Rusia?',
        answer: 'No hay de qué preocuparse, no se requiere la presencia del apoderado para emitir el poder notarial. Es suficiente proporcionar sus datos de pasaporte e información de registro.',
      },
      {
        question: '¿Necesito llevar a mi hijo a la cita en el consulado si el poder notarial se organiza en su nombre?',
        answer: 'Depende de la edad del niño. No se requiere la presencia de niños menores de 14 años. Los niños de 14 a 18 años organizan poderes notariales en su propio nombre, pero con el consentimiento de uno de los padres, por lo que es obligatoria la presencia tanto del niño como del padre.',
      },
      {
        question: '¿Qué pasaporte debo presentar para organizar un documento notarial?',
        answer: 'Se da prioridad al pasaporte interno. Si el pasaporte interno no ha sido emitido o ha expirado, se requerirá un pasaporte extranjero válido. Si ambos pasaportes están disponibles, se deben llevar ambos pasaportes a la cita en el consulado.',
      },
      {
        question: 'Soy un ciudadano extranjero. ¿Puedo organizar un poder notarial para un ciudadano ruso?',
        answer: 'Sí, por supuesto, es posible.',
      },
      {
        question: '¿Es posible organizar un poder notarial en el consulado ruso para representación en Kazajistán o Bielorrusia?',
        answer: 'Sí. De acuerdo con la Convención sobre Asistencia Legal, de la cual son participantes Rusia, Kazajistán, Bielorrusia, Tayikistán, Georgia y otros, un poder notarial emitido en el Consulado ruso es aceptado por las agencias gubernamentales y las instituciones de estos países sin certificación adicional (apostilla).',
      },
      {
        question: '¿Cuál es el período máximo para el cual se puede emitir un poder notarial?',
        answer: 'Puede emitir poderes notariales con cualquier validez. Al mismo tiempo, se debe especificar un período específico de validez; no se permite la certificación de poderes notariales "indefinidos".',
      },
      {
        question: '¿Se pueden especificar varios apoderados en un poder notarial?',
        answer: 'Sí, los poderes notariales pueden emitirse en nombre de una o varias personas, para una o varias personas.',
      },
    ],
  ],

  faqList: [
    {
      userName: [
        'Могу ли я оформить доверенность без моего личного присутствия?',
        'Can I arrange a Power of Attorney without my personal presence?',
        '我可以在没有我亲自到场的情况下办理授权书吗？',
        '¿Puedo arreglar un poder notarial sin mi presencia personal?',
      ],
      desc: [
        [
          'Нет, личное присутствие доверителя обязательно.',
          "No, the principal's personal presence is mandatory.",
          '不，可以授权人亲自到场。',
          'No, la presencia del otorgante es obligatoria.',
        ],
      ],
    },
    {
      userName: [
        'Что делать, если человек, на которого оформляется доверенность, находится в РФ?',
        'What should I do if the person for whom the Power of Attorney is being arranged is located in Russia?',
        '如果办理授权书的人在俄罗斯，我该怎么办？',
        '¿Qué debo hacer si la persona para quien se está arreglando el poder notarial se encuentra en Rusia?',
      ],
      desc: [
        [
          'Ничего страшного, присутствие поверенного для оформления доверенности не требуется. Достаточно предоставить его паспортные данные и информацию о прописке.',
          'Nothing to worry about, the presence of the attorney to arrange the Power of Attorney is not required. It is sufficient to provide their passport details and registration information.',
          '没什么好担心的，办理授权书不需要律师在场。只需提供其护照信息和注册信息即可。',
          'No hay nada de qué preocuparse, la presencia del abogado para emitir el poder notarial no es necesaria. Es suficiente con proporcionar sus datos del pasaporte y la información de registro.',
        ],
      ],
    },
    {
      userName: [
        'Нужно ли брать с собой ребёнка на приём в консульство, если доверенность оформляется от его имени?',
        'Do I need to bring my child to the consulate appointment if the Power of Attorney is being arranged on their behalf?',
        '如果为孩子办理授权书，我需要带他们去领事馆吗？',
        '¿Necesito llevar a mi hijo a la cita en el consulado si se está arreglando el poder notarial en su nombre?',
      ],
      desc: [
        [
          'Зависит от возраста ребёнка. Присутствие ребёнка до 14 лет не требуется. Дети в возрасте от 14 до 18 лет оформляют доверенности от своего имени, но с согласия одного из родителей, поэтому обязательно присутствие как ребёнка, так и родителя.',
          'It depends on the age of the child. The presence of children under 14 years of age is not required. Children aged 14 to 18 arrange Powers of Attorney in their own name, but with the consent of one of the parents, so the presence of both the child and the parent is mandatory.',
          '这取决于孩子的年龄。14岁以下的孩子不需要到场。14至18岁的孩子以自己的名义办理授权书，但需征得父母的同意，因此孩子和父母都必须到场。',
          'Depende de la edad del niño. No se requiere la presencia de los niños menores de 14 años. Los niños de 14 a 18 años gestionan poderes en su propio nombre, pero con el consentimiento de uno de los padres, por lo que es obligatoria la presencia tanto del niño como del padre.',
        ],
      ],
    },
    {
      userName: [
        'Какой паспорт нужно предъявить для оформления нотариального документа?',
        'Which passport do I need to present to arrange a notarized document?',
        '我需要出示哪种护照来办理公证文件？',
        '¿Qué pasaporte debo presentar para arreglar un documento notarial?',
      ],
      desc: [
        [
          'Приоритет отдаётся внутреннему паспорту. В случае, если внутренний паспорт не оформлялся или просрочен — потребуется действующий загранпаспорт. Если имеются оба паспорта, то необходимо взять на приём в консульство два паспорта.',
          'Priority is given to the internal passport. If the internal passport has not been issued or has expired, a valid foreign passport will be required. If both passports are available, both passports must be taken to the consulate appointment.',
          '优先考虑内务护照。如果没有办理内务护照或内务护照已过期，则需要有效的护照。如果两本护照均可用，则必须同时带到领事馆。',
          'Se da prioridad al pasaporte interno. Si el pasaporte interno no se ha emitido o ha caducado, se requerirá un pasaporte extranjero válido. Si ambos pasaportes están disponibles, ambos deben llevarse a la cita consular.',
        ],
      ],
    },
    {
      userName: [
        'Я — иностранный гражданин. Могу ли оформить доверенность на гражданина РФ?',
        'I am a foreign citizen. Can I arrange a Power of Attorney for a Russian citizen?',
        '我是外国公民。我可以为俄罗斯公民办理授权书吗？',
        'Soy un ciudadano extranjero. ¿Puedo arreglar un poder notarial para un ciudadano ruso?',
      ],
      desc: [
        [
          'Да, конечно, это возможно.',
          'Yes, of course, it is possible.',
          '是的，当然可以。',
          'Sí, por supuesto, es posible.',
        ],
      ],
    },
    {
      userName: [
        'Возможно ли в Российском консульстве оформить доверенность для представления интересов в Казахстане или Белоруссии?',
        'Is it possible to arrange a Power of Attorney at the Russian consulate for representation in Kazakhstan or Belarus?',
        '在俄罗斯领事馆办理在哈萨克斯坦或白俄罗斯的代表授权书可行吗？',
        '¿Es posible arreglar un poder notarial en el consulado ruso para representación en Kazajistán o Bielorrusia?',
      ],
      desc: [
        [
          'Да. В соответствии с Конвенцией о правовой помощи, участниками которой являются Россия и Казахстан, Беларусь, Таджикистан, Грузия и др., доверенность, оформленная в Консульстве России, принимается госорганами и учреждениями этих стран, без дополнительного удостоверения (апостиля).',
          'Yes. In accordance with the Convention on Legal Assistance, of which Russia, Kazakhstan, Belarus, Tajikistan, Georgia, and others are participants, a Power of Attorney arranged at the Russian Consulate is accepted by the government agencies and institutions of these countries without additional certification (apostille).',
          '是的。根据俄罗斯与哈萨克斯坦、白俄罗斯、塔吉克斯坦、格鲁吉亚等国签署的法律援助公约，在俄罗斯领事馆办理的授权书可被这些国家的政府机构和机构接受，无需额外认证（公证）。',
          'Sí. De acuerdo con la Convención sobre Asistencia Jurídica, de la cual Rusia, Kazajistán, Bielorrusia, Tayikistán, Georgia y otros son participantes, un poder notarial arreglado en el consulado ruso es aceptado por las agencias gubernamentales e instituciones de estos países sin certificación adicional (apostilla).',
        ],
      ],
    },
    {
      userName: [
        'На какой максимальный срок можно оформить доверенность?',
        'What is the maximum period for which a Power of Attorney can be arranged?',
        '可以办理授权书的最长期限是多久？',
        '¿Cuál es el período máximo para el cual se puede arreglar un poder notarial?',
      ],
      desc: [
        [
          'Вы вправе оформить доверенность с любым сроком действия. Вместе c тем в доверенности должен быть указан конкретный срок её действия, удостоверение «бессрочных» доверенностей не допускается.',
          'You can issue Powers of Attorney with any validity. At the same time, a specific period of validity must be specified; the certification of "indefinite" Powers of Attorney is not allowed.',
          '您可以签发任何有效期的授权书。同时，必须指定有效期；不允许认证“无限期”授权书。',
          'Puede emitir poderes con cualquier validez. Al mismo tiempo, debe especificarse un período específico de validez; no se permite la certificación de poderes "indefinidos".',
        ],
      ],
    },
    {
      userName: [
        'Можно ли в доверенности указать несколько доверенных лиц?',
        'Can multiple attorneys be specified in a Power of Attorney?',
        '在授权书中可以指定多个受托人吗？',
        '¿Se pueden especificar varios apoderados en un poder notarial?',
      ],
      desc: [
        [
          'Да, доверенности могут быть оформлены от имени одного или нескольких лиц, на имя одного или нескольких лиц.',
          'Yes, Powers of Attorney can be arranged on behalf of one or several persons, for one or several persons.',
          '是的，授权书可以以一个或多个名义为一个或多个受托人办理。',
          'Sí, los poderes notariales pueden ser arreglados en nombre de una o varias personas, para una o varias personas.',
        ],
      ],
    },
  ],

  askQuestions: [
    'Задать вопрос',
    'Ask questions',
    '提问',
    'Hacer preguntas',
],

  imagesTypesOfPoa: [
    docImage,
    houseImage,
    houseImage,
    houseImage,
    buildingImage,
    carImage,
    stampBlackImage,
    coinImage,
    coinImage,
    coinImage,
    stampBlackImage,
    hammerImage,
    courtImage,
    closeImage,
  ],
  typesOfPoa: [
    ['Генеральная', 'General', '一般', 'General'],
    ['На дарение недвижимости', 'Inheritance of Real Estate', '遗产房产', 'Herencia de bienes raíces'],
    ['На покупку недвижимости', 'For the purchase of real estate', '购买房产', 'Para la compra de bienes raíces'],
    ['На продажу недвижимости', 'For the sale of real estate', '出售房产', 'Para la venta de bienes raíces'],
    ['На продажу доли общества', 'For the sale of a share in a company', '出售公司股份', 'Para la venta de una participación en una empresa'],
    ['На машину', 'For a car', '购车', 'Para un coche'],
    ['На ведение наследственного дела', 'For handling inheritance matters', '处理遗产事务', 'Para manejar asuntos de herencia'],
    ['На оформление пенсии', 'For processing pension documents', '办理养老金', 'Para el procesamiento de documentos de pensión'],
    ['На оформление материнского капитала', 'For processing maternity capital documents', '办理母亲资本', 'Para el procesamiento de documentos de capital materno'],
    ['На управление банковскими счетами', 'For managing bank accounts', '管理银行账户', 'Para gestionar cuentas bancarias'],
    ['На истребование и апостилирование документов', 'For retrieval and apostilling of documents', '获取和公证文件', 'Para la recuperación y apostilla de documentos'],
    ['На представительство в суде', 'For legal representation in court', '法庭代理', 'Para representación legal en la corte'],
    ['На представительство в госорганах', 'For representation in government agencies', '在政府机构的代表', 'Para representación en agencias gubernamentales'],
    ['Распоряжение об отмене доверенности', 'Revocation of Power of Attorney', '撤销授权书', 'Revocación de Poder Notarial'],
],




  PoatServicesInteractuation: [



      {
        text: [
          [
            'Официально понятия «генеральная» доверенность не существует. Как правило, под этим понимают доверенность на совершение самого широкого круга юридически значимых действий: распоряжение недвижимостью и транспортным средством, банковскими счетами, представительство в государственных организациях, и прочие конкретные полномочия, которые указываются в тексте документа',
            "The concept of a 'general' Power of Attorney does not officially exist. Typically, it refers to a Power of Attorney granting the broadest range of legally significant actions: managing real estate and vehicles, bank accounts, representing in government organizations, and other specific authorities specified in the document text.",
            "官方不存在“总授权书”一词。通常，这指的是授予最广泛法律行为范围的授权书：管理不动产和车辆、银行账户、在政府机构中代表、以及文档中指定的其他具体权力。",
            "Oficialmente, no existe el término 'poder general'. Generalmente, se refiere a un poder que otorga el mayor alcance de acciones legalmente significativas: gestionar bienes inmuebles y vehículos, cuentas bancarias, representación en organizaciones gubernamentales, y otras autoridades específicas especificadas en el texto del documento."
          ]
        ],
        list: null,
      },
      {
        text: null,
        list: {
          headers: [
            ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
            ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:'],
          ],
          body: [
            [
              ['подписать договор дарения', 'Sign a donation agreement', '签订捐赠协议', 'Firmar un acuerdo de donación'],
              [
                'зарегистрировать договор и переход права собственности',
                'Register the contract and transfer property rights',
                '注册合同并转让财产所有权',
                'Registrar el contrato y transferir derechos de propiedad'
              ],
              [
                'представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы',
                'Represent the interests of the principal in various government agencies, submit applications, and request documents on their behalf',
                '代表委托人在不同的政府机构中，提交申请并代其请求文件',
                'Representar los intereses del mandante en diversas agencias gubernamentales, presentar solicitudes y solicitar documentos en su nombre'
              ],
            ],
            [
              ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
              ['документы на имущество', 'property documents', '财产文件', 'documentos de propiedad'],
              ['паспортные данные одаряемого', 'personal data of the recipient', '受赠人护照信息', 'datos personales del receptor'],
              [
                'разрешение органов опеки на сделку (если какая‑либо часть недвижимости принадлежит ребёнку)',
                'permission from guardianship authorities for the transaction (if any part of the property belongs to a child)',
                '监护部门的交易许可（如果部分财产属于儿童）',
                'permiso de las autoridades de tutela para la transacción (si alguna parte de la propiedad pertenece a un niño)'
              ],
              [
                'согласие супруга на дарение недвижимости (если имущество было приобретено в браке). Можно оформить одновременно с доверенностью',
                "spouse's consent to donate real estate (if the property was acquired during marriage). Can be issued simultaneously with the Power of Attorney",
                '配偶同意捐赠房地产（如财产在婚姻期间获得）。可以与授权书同时办理',
                'consentimiento del cónyuge para donar bienes inmuebles (si la propiedad se adquirió durante el matrimonio). Puede emitirse simultáneamente con el poder'
              ],
              [
                'подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)',
                'confirmation of relationship between the principal and the attorney (to receive discounts on consular fees)',
                '委托人与代理人之间的关系确认（以获得领事费减免）',
                'confirmación de relación entre el mandante y el abogado (para recibir descuentos en tarifas consulares)'
              ],
            ],
          ],
        },
      }
    
,    

  
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:'],
    ],
    body: [
      [
        ['представлять интересы доверителя в государственных органах', "representing the principal's interests in government agencies", '在政府机构中代表委托人的利益', 'representar los intereses del mandante en agencias gubernamentales'],
        ['заключать от имени доверителя договоры на покупку недвижимости', 'entering into property purchase contracts on behalf of the principal', '代表委托人签订购买不动产合同', 'celebrar contratos de compra de propiedades en nombre del mandante'],
        ['заключать кредитные договора', 'entering into loan agreements', '签订贷款合同', 'celebrar contratos de préstamo']
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
        ['согласие супруга на покупку недвижимости (если доверитель состоит в браке). Можно оформить одновременно с доверенностью', "spouse's consent to purchase real estate (if the principal is married). Can be issued simultaneously with the Power of Attorney", '配偶同意购买不动产（如委托人已婚）。可以与授权书同时办理', 'consentimiento del cónyuge para la compra de bienes inmuebles (si el mandante está casado). Puede emitirse simultáneamente con el poder'],
        ['подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)', 'confirmation of relationship between the principal and the attorney (to receive discounts on consular fees)', '委托人与代理人之间的关系确认（以获得领事费减免）', 'confirmación de relación entre el mandante y el abogado (para recibir descuentos en tarifas consulares)']
      ]
    ]
  }
},
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:']
    ],
    body: [
      [
        ['продать / обменять указанный объект недвижимости', 'Sell / exchange the specified real estate object', '出售/交换指定的房地产', 'vender/intercambiar el inmueble especificado'],
        ['получить деньги по сделке', 'Receive money for the transaction', '交易款项', 'recibir dinero de la transacción'],
        ['сдать в аренду', 'Rent out', '出租', 'arrendar'],
        ['зарегистрировать недвижимость на себя или третьих лиц', 'Register the real estate for oneself or third parties', '将房地产登记在自己或第三方名下', 'registrar el inmueble a nombre propio o de terceros'],
        ['представлять интересы доверителя в различных госучреждениях: Росреестр, МФЦ, банк, отдел по вопросам миграции МВД', 'Represent the interests of the principal in various government agencies: Rosreestr, MFC, bank, migration department of the Ministry of Internal Affairs', '在不同的政府机构中代表委托人的利益：不动产登记局、多功能服务中心、银行、内政部移民事务部门', 'representar los intereses del mandante en diversas agencias gubernamentales: Rosreestr, MFC, banco, departamento de migración del Ministerio del Interior']
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
        ['документы на имущество', 'Documents for the property', '财产文件', 'documentos de la propiedad'],
        ['разрешение органов опеки на сделку (если какая-либо часть недвижимости принадлежит ребёнку)', 'Permission from the guardianship authorities for the transaction (if any part of the real estate belongs to a child)', '监护部门的交易许可（如果部分房地产属于儿童）', 'permiso de las autoridades de tutela para la transacción (si alguna parte del inmueble pertenece a un niño)'],
        ['согласие супруга на продажу недвижимости (если имущество было приобретено в браке). Можно оформить одновременно с доверенностью', "Spouse's consent to sell the real estate (if the property was acquired during marriage). Can be processed simultaneously with the Power of Attorney", '配偶同意出售房地产（如财产在婚姻期间获得）。可以与授权书同时办理', 'consentimiento del cónyuge para la venta del inmueble (si el bien fue adquirido durante el matrimonio). Puede emitirse simultáneamente con el poder'],
        ['подтверждение родства доверителя и поверенного (для получения льгот на оплату консульского сбора)', 'Confirmation of the relationship between the principal and the attorney (to obtain discounts on consular fees)', '委托人与代理人之间的关系确认（以获得领事费减免）', 'confirmación de la relación entre el mandante y el abogado (para obtener descuentos en tarifas consulares)']
      ]
    ]
  }
},


{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:'],
    ],
    body: [
      [
        ['представлять интересы доверителя в различных госучреждениях, подавать заявления и запрашивать от его имени документы', 'Represent the interests of the principal in various government agencies, submit applications, and request documents on their behalf', '在不同的政府机构中代表委托人利益，提交申请并代为请求文件', 'representar los intereses del mandante en varias agencias gubernamentales, presentar solicitudes y solicitar documentos en su nombre'],
        ['проводить от его имени переговоры и решать все возникающие спорные вопросы', 'Conduct negotiations and resolve all arising disputes on their behalf', '代表其进行谈判并解决所有出现的争议问题', 'realizar negociaciones y resolver todas las disputas que surjan en su nombre'],
        ['заключить и подписать от его имени договор на продажу доли в уставном капитале общества', 'Conclude and sign, on their behalf, a contract for the sale of a share in the charter capital of the company', '代表其签订并签署公司股权转让合同', 'celebrar y firmar en su nombre un contrato para la venta de una participación en el capital social de la empresa'],
        ['получить по данному договору деньги', 'Receive payment under this contract', '根据该合同接收款项', 'recibir el pago en virtud de este contrato']
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
        ['Согласия супруга на продажу доли общества (если юридическое лицо было создано или приобретено в браке). Можно оформить одновременно с доверенностью.', "Spouse's consent to the sale of the company's share (if the legal entity was created or acquired during marriage). Can be formalized simultaneously with the Power of Attorney.", '配偶同意出售公司股份（如果法人是在婚姻期间创建或收购的）。可以与授权书同时办理。', 'consentimiento del cónyuge para la venta de la participación de la empresa (si la entidad legal fue creada o adquirida durante el matrimonio). Puede formalizarse junto con el poder']
      ]
    ]
  }
},
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:']
    ],
    body: [
      [
        ['управлять данным транспортным средством', 'manage the specified vehicle', '管理指定的车辆', 'administrar el vehículo especificado'],
        ['забирать машину со штрафстоянки', 'retrieve the car from the impound lot', '从扣押车场取回车辆', 'retirar el coche del depósito'],
        ['продавать автомобиль', 'sell the car', '出售汽车', 'vender el coche'],
        ['получать финансовые средства от продажи', 'receive financial proceeds from the sale', '获得出售款项', 'recibir el producto de la venta'],
        ['сдать в аренду, заложить или обменять указанный в документе автомобиль', 'rent out, pawn, or exchange the specified car mentioned in the document', '出租、抵押或交换文件中指定的汽车', 'alquilar, empeñar o intercambiar el coche especificado mencionado en el documento'],
        ['снимать и ставить его на учёт в ГИБДД', 'take it off and put it back on the registration in the traffic police', '在交警处注销或重新登记', 'dar de baja o volver a registrar el vehículo en la policía de tráfico'],
        ['получать дубликаты и вносить изменения в регистрационные документы', 'receive duplicates and make changes to the registration documents', '领取副本并修改登记文件', 'recibir duplicados y hacer cambios en los documentos de registro'],
        ['получать или менять номерные знаки', 'receive or change license plates', '领取或更换车牌', 'recibir o cambiar las placas'],
        ['проходить технический осмотр', 'pass a technical inspection', '通过技术检查', 'pasar una inspección técnica'],
        ['оформлять страховку и получать страховое возмещение', 'arrange insurance and receive insurance compensation', '办理保险并获得赔偿', 'contratar seguro y recibir compensación']
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
        ['паспорт транспортного средства (ПТС) или электронный ПТС', 'vehicle registration certificate (PTS) or electronic PTS', '车辆登记证书（PTS）或电子PTS', 'certificado de registro del vehículo (PTS) o PTS electrónico'],
        ['свидетельство о регистрации ТС', 'vehicle registration certificate', '车辆登记证书', 'certificado de registro del vehículo']
      ]
    ]
  }
},
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:']
    ],
    body: [
      [
        [
          'подавать заявления в нотариальную контору на принятие наследство или отказ от его получения', 
          'submit applications to a notary office for accepting inheritance or waiving its receipt', 
          '向公证处提交接受遗产或放弃遗产的申请', 
          'presentar solicitudes en la notaría para aceptar la herencia o renunciar a ella'
        ],
        [
          'запрашивать и получать документы в государственных органах (ЗАГС, МВД, архивы), необходимые для наследственного дела, среди которых Свидетельство о праве на наследство', 
          "request and obtain documents from state authorities (registrar's offices, Ministry of Internal Affairs, archives) necessary for the inheritance case, including the Certificate of Right to Inheritance", 
          '在政府机关（注册处、内政部、档案）申请并获取继承事务所需的文件，包括继承权证书', 
          'solicitar y obtener documentos en organismos estatales (oficinas del registro civil, Ministerio del Interior, archivos) necesarios para el caso de herencia, incluido el Certificado de Derecho de Herencia'
        ],
        [
          'знакомиться с содержанием документа, когда доверенность выдаётся на оформление наследства по завещанию', 
          'review the contents of the document when the Power of Attorney is issued for the inheritance formalization by will', 
          '查看文件内容，适用于根据遗嘱继承时', 
          'revisar el contenido del documento cuando el Poder se emite para la formalización de la herencia por testamento'
        ],
        [
          'принимать участие в разделе наследства, предусматривающем выплату (получение) денежной компенсации', 
          'participate in the inheritance division, involving payment (receipt) of monetary compensation', 
          '参与遗产分配，包括支付或接收货币补偿', 
          'participar en la división de la herencia, incluyendo el pago (o recepción) de compensación monetaria'
        ],
        [
          'регистрировать в государственных органах регистрации прав на наследуемое имущество', 
          'register the inherited property rights in state registration authorities', 
          '在国家登记机关登记遗产财产权', 
          'registrar los derechos de propiedad heredados en las autoridades de registro estatales'
        ]
      ],
      [
        [
          'свидетельство о смерти или данные о наследодателе: ФИО умершего, дата смерти, адрес регистрации места жительства на момент смерти', 
          "death certificate or deceased's data: full name of the deceased, date of death, address of residence registration at the time of death", 
          '死亡证明或去世者信息：姓名、死亡日期、去世时的注册居住地址', 
          'certificado de defunción o datos del fallecido: nombre completo, fecha de fallecimiento, dirección registrada en el momento de la muerte'
        ]
      ]
    ]
  }
},
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:']
    ],
    body: [
      [
        ['получать пенсионные выплаты', 'receive pension payments', '领取养老金', 'recibir pagos de pensión'],
        [
          'представлять интересы доверителя в Пенсионном фонде, подавать от его имени заявления о назначении (восстановлении) пенсионных выплат', 
          'represent the interests of the principal at the Pension Fund, submit applications on their behalf for the appointment (reinstatement) of pension payments', 
          '代表委托人在养老金基金处提交养老金申领或恢复申请', 
          'representar los intereses del mandante en el Fondo de Pensiones, presentar solicitudes en su nombre para la asignación o reinstalación de los pagos de pensión'
        ],
        [
          'получать документы, необходимых для оформления пенсионных выплат, в частности, пенсионного удостоверения', 
          'obtain documents necessary for processing pension payments, including a pension certificate', 
          '获取办理养老金所需的文件，特别是养老金证明', 
          'obtener los documentos necesarios para la tramitación de pagos de pensión, incluyendo el certificado de pensión'
        ],
        [
          'открыть персональный пенсионный банковский счёт для доверителя', 
          'open a personal pension bank account for the principal', 
          '为委托人开设个人养老金银行账户', 
          'abrir una cuenta bancaria de pensión personal para el mandante'
        ]
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)'],
        [
          'данные банковского счета (если уже открыт)', 
          'bank account details (if already opened)', 
          '银行账户信息（如果已开设）', 
          'detalles de la cuenta bancaria (si ya está abierta)'
        ]
      ]
    ]
  }
}
,

{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:']
    ],
    body: [
      [
        [
          'представлять интересы доверителя в Пенсионном фонде', 
          'represent the interests of the principal in the Pension Fund', 
          '代表委托人在养老金基金处的利益', 
          'representar los intereses del mandante en el Fondo de Pensiones'
        ],
        [
          'подавать от его имени заявления о выдаче Государственного Сертификата на материнский (семейный) капитал', 
          'submit applications on their behalf for the issuance of the State Certificate for Maternal (Family) Capital', 
          '代表其提交申请，领取国家母亲（家庭）资本证书', 
          'presentar solicitudes en su nombre para la emisión del Certificado Estatal de Capital Materno (Familiar)'
        ],
        [
          'получить от его имени Государственного Сертификата на материнский (семейный) капитал', 
          'receive the State Certificate for Maternal (Family) Capital on their behalf', 
          '以其名义领取国家母亲（家庭）资本证书', 
          'recibir en su nombre el Certificado Estatal de Capital Materno (Familiar)'
        ]
      ],
      [
        ['СНИЛС (если имеется)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)']
      ]
    ]
  }
},
{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '可能包含的权限：', 'Poderes que puede incluir:']
    ],
    body: [
      [
        [
          'открывать / закрывать счета доверителя в банке', 
          'opening/closing accounts for the principal at the bank', 
          '为委托人在银行开设或关闭账户', 
          'abrir/cerrar cuentas bancarias para el mandante'
        ],
        [
          'заключать и расторгать от имени доверителя договора с банками', 
          'entering into and terminating agreements with banks on behalf of the principal', 
          '代表委托人与银行签订或终止协议', 
          'firmar y rescindir contratos en nombre del mandante con bancos'
        ],
        [
          'переводить денежные средства со счета доверителя на иной банковский счёт', 
          "transferring funds from the principal's account to another bank account", 
          '从委托人的账户转账至其他银行账户', 
          'transferir fondos de la cuenta del mandante a otra cuenta bancaria'
        ],
        [
          'вносить и снимать денежные средства со счета', 
          'depositing and withdrawing funds from the account', 
          '存入或取出账户资金', 
          'depositar y retirar fondos de la cuenta'
        ],
        [
          'получать выплаты, компенсации и проценты по вкладам', 
          'receiving payments, compensations, and interest on deposits', 
          '领取存款的支付、补偿和利息', 
          'recibir pagos, compensaciones e intereses sobre los depósitos'
        ]
      ]
    ]
  }
}
,

{
  text: [
    [
      'Дополнительно могут потребоваться документы на объект сделки (недвижимость, земельный участок, организация и другие) или уточняющая информация про документы, которые планируются к истребованию.',
      'Additionally, documents related to the transactional object (real estate, land plot, organization, etc.) may be required, or clarifying information about the documents planned to be obtained.',
      '此外可能需要与交易对象（房地产、土地、组织等）相关的文件，或计划获取的文件的澄清信息。',
      'Además, pueden ser necesarios documentos relacionados con el objeto de la transacción (bienes raíces, terreno, organización, etc.) o información aclaratoria sobre los documentos que se planea obtener.'
    ],
  ],
  list: {
    headers: [
      [
        'Данный вид доверенности будет полезен в случае:',
        'This type of Power of Attorney will be useful in the following cases:',
        '这种类型的授权书在以下情况下会有用：',
        'Este tipo de poder notarial será útil en los siguientes casos:'
      ],
      ['Какие полномочия может включать:', 'What powers can it include:', '它可以包含什么权力：', '¿Qué poderes puede incluir:'],
    ],
    body: [
      [
        [
          'сбора документов на получение ВНЖ или гражданства другой страны',
          'Collecting documents for obtaining a residence permit or citizenship in another country.',
          '收集获得其他国家的居留许可或国籍所需的文件。',
          'Recopilar documentos para obtener un permiso de residencia o ciudadanía en otro país.'
        ],
        [
          'омологации документов об образовании',
          'Apostilling educational documents.',
          '教育文件的公证。',
          'Apostillar documentos educativos.'
        ],
        [
          'подготовке к сделке с недвижимостью или земельным участком, находящихся в РФ, и в других случаях',
          'Preparing for a real estate transaction or land plot located in the Russian Federation, and in other cases.',
          '准备在俄罗斯联邦进行房地产交易或土地交易，以及其他情况。',
          'Prepararse para una transacción inmobiliaria o un terreno ubicado en la Federación de Rusia, y en otros casos.'
        ],
      ],
      [
        [
          'представлять интересы доверителя в разных государственных учреждениях, например, БТИ, ЕИРЦ, ЗАГС, ДЕЗ, земельный комитет, регистрационная служба, кадастровая палата, Министерство Образования, нотариальная контора, подавать в них от его имени заявления и получать документы: выписки, справки и дубликаты',
          'Representing the interests of the principal in various government institutions, such as BTI, EIRC, ZAGS, DEZ, Land Committee, registration office, cadastral chamber, Ministry of Education, notary office, submitting applications on their behalf, and obtaining documents: extracts, certificates, and duplicates.',
          '代表委托人在不同的政府机构中的利益，例如BTI、EIRC、ZAGS、DEZ、土地委员会、登记处、地籍局、教育部、 нотариальная контора，代表其提交申请并获取文件：摘录、证明和副本。',
          'Representar los intereses del mandante en diversas instituciones gubernamentales, como BTI, EIRC, ZAGS, DEZ, Comité de Tierras, oficina de registro, cámara catastral, Ministerio de Educación, notaría, presentando solicitudes en su nombre y obteniendo documentos: extractos, certificados y duplicados.'
        ],
        [
          'регистрировать и подавать документы',
          'registering and submitting documents,',
          '登记和提交文件，',
          'registrar y presentar documentos,'
        ],
        [
          'оплачивать тарифы, пошлины, сборы',
          'paying tariffs, fees, and charges.',
          '支付关税、费用和收费。',
          'pagar tarifas, impuestos y tasas.'
        ],
      ],
    ],
  },
},
{
  text: null,
  list: {
    headers: [
      [
        'Данный вид доверенности позволяет представлять интересы в суде:',
        'This type of Power of Attorney allows representing interests in court:',
        '这种类型的授权书允许在法庭上代表利益：',
        'Este tipo de poder notarial permite representar intereses en la corte:'
      ],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:'],
    ],
    body: [
      [
        ['по гражданским делам', 'In civil cases', '在民事案件中', 'En casos civiles'],
        ['по уголовным делам', 'In criminal cases', '在刑事案件中', 'En casos penales'],
        ['по административным делам', 'In administrative cases', '在行政案件中', 'En casos administrativos'],
        [
          'по делам, рассматриваемым арбитражным судом (полномочия: отзыв и подписание искового заявления, заключение мирового соглашения и другие)',
          'In cases considered by the arbitration court (authorities: withdrawal and signing of the statement of claim, conclusion of a settlement agreement, and others)',
          '在仲裁法院审理的案件中（权限：撤回和签署索赔声明、达成和解协议等）',
          'En los casos considerados por el tribunal de arbitraje (autoridades: retiro y firma de la declaración de reclamación, conclusión de un acuerdo de conciliación, entre otros)'
        ],
        [
          'в службе судебных приставов (полномочия: обжалование действий или бездействия судебных приставов, обжалование постановлений постановлений и другие)',
          'In the bailiff service (authorities: appealing actions or inactions of bailiffs, appealing resolutions, and others)',
          '在法警服务中（权限：对法警的行为或不作为提出上诉，提出裁决的上诉等）',
          'En el servicio de alguaciles (autoridades: apelar acciones o inacciones de los alguaciles, apelar resoluciones, entre otros)'
        ],
      ],
      [['СНИЛС (если имеются)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)']],
    ],
  },
}
,

{
  text: null,
  list: {
    headers: [
      ['Какие полномочия может включать:', 'What powers may include:', '权力可能包括：', 'Los poderes pueden incluir:'],
      ['Дополнительно потребуются:', 'Additionally required:', '此外需要：', 'Además requeridos:'],
    ],
    body: [
      [
        [
          'действовать от имени доверителя в государственных ведомствах, организациях и службах: ГИБДД, ФССП, ЗАГС, страховых компаниях, Социальном фонде, суде, правоохранительных органах, банках и так далее',
          'Act on behalf of the principal in state authorities, organizations, and services: traffic police, Federal Bailiff Service, Registry Office, insurance companies, Social Security Fund, court, law enforcement agencies, banks, and so on.',
          '代表委托人在国家机关、组织和服务中的行为：交通警察、联邦法警服务、登记处、保险公司、社会保障基金、法院、执法机关、银行等。',
          'Actuar en nombre del mandante en autoridades estatales, organizaciones y servicios: policía de tráfico, Servicio Federal de Alguaciles, Registro Civil, compañías de seguros, Fondo de Seguridad Social, tribunal, agencias de aplicación de la ley, bancos, etc.'
        ],
        [
          'подавать заявления о государственной регистрации прав или сделок',
          'Submit applications for state registration of rights or transactions.',
          '提交国家注册权利或交易的申请。',
          'Presentar solicitudes para el registro estatal de derechos o transacciones.'
        ],
        [
          'распоряжаться зарегистрированными в государственных реестрах правами',
          'Dispose of rights registered in state registers.',
          '处理在国家登记册中注册的权利。',
          'Disponer de derechos registrados en los registros estatales.'
        ],
        [
          'представлять интересы ИП в налоговой инспекции',
          'Represent the interests of an individual entrepreneur in the tax office.',
          '代表个体企业主在税务局的利益。',
          'Representar los intereses de un emprendedor individual en la oficina de impuestos.'
        ],
        [
          'представлять интересы в исполнительном производстве',
          'Represent interests in enforcement proceedings.',
          '在强制执行程序中代表利益。',
          'Representar intereses en procedimientos de ejecución.'
        ],
      ],
      [['СНИЛС (если имеются)', 'SNILS (if available)', '养老保险号（如有）', 'SNILS (si está disponible)']],
    ],
  },
},
{
  text: [
    [
      'Доверитель вправе отменить уже выданную доверенность в любой момент (исключением является только безотзывная доверенность, которую оформляют предприниматели).',
      'The principal has the right to revoke a Power of Attorney that has already been issued at any time (with the exception of irrevocable Powers of Attorney, which are issued by entrepreneurs).',
      '委托人有权在任何时候撤销已经签发的授权书（唯一的例外是由企业家签发的不可撤销授权书）。',
      'El mandante tiene derecho a revocar un poder notarial que ya ha sido emitido en cualquier momento (con la excepción de los poderes irrevocables, que son emitidos por empresarios).',
    ],
    [
      'Запись об аннулировании документа вносится в единый электронный реестр доверенностей, которым вправе воспользоваться любой гражданин.',
      "The record of the document's annulment is entered into the Unified Electronic Register of Powers of Attorney, which can be accessed by any citizen.",
      '文档作废的记录被输入到统一电子授权登记册中，任何公民均可访问。',
      'El registro de la anulación del documento se ingresa en el Registro Electrónico Unificado de Poderes Notariales, al que puede acceder cualquier ciudadano.',
    ],
  ],
  list: null,
}
 
   
  ],


  buttonFormalize: ['Оформить', 'To formalize', '办理', 'Formalizar'],
  buttonAsk: ['Спросить', 'Ask a question', '询问', 'Hacer una pregunta'],
  whichTypes: [
    'Какая доверенность вам необходима?',
    'What Power of Attorney do you need?',
    '您需要什么样的授权书？',
    '¿Qué tipo de poder notarial necesita?',
  ],

  typeOfPoa: ['Вид доверенности', 'Type of Power of Attorney', '授权书类型', 'Tipo de poder notarial'],
  chooseFromList: ['Выберите из списка', 'Choose from the list', '从列表中选择', 'Elija de la lista'],
  describeService: [
    'Или опишите своими словами, какая доверенность вам нужна',
    'Or describe in your own words what type of Power of Attorney you need.',
    '或者用您自己的话描述您需要什么类型的授权书。',
    'O describa con sus propias palabras qué tipo de poder notarial necesita.',
  ],
  whoGives: ['Кто доверяет?', 'Who trusts?', '谁信任？', '¿Quién confía?'],
  issuerStatus: ['Статус доверителя', "Trustor's status", '委托人身份', 'Estado del mandante'],
  addMoreTruster: ['Добавить ещё доверителя', 'Add another trustor', '添加更多委托人', 'Agregar otro mandante'],
  trustersList: [
    ['первый доверитель', 'first trustor', '第一委托人', 'primer mandante'],
    ['второй доверитель', 'second trustor', '第二委托人', 'segundo mandante'],
    ['третий доверитель', 'third trustor', '第三委托人', 'tercer mandante'],
    ['четвёртый доверитель', 'fourth trustor', '第四委托人', 'cuarto mandante'],
    ['пятый доверитель', 'fifth trustor', '第五委托人', 'quinto mandante'],
  ],
  toWhomGives: ['Кому доверяет?', 'To whom trust?', '信任谁？', '¿A quién confía?'],
  representativeStatus: ['Статус представителя', 'Representative status', '代表身份', 'Estado del representante'],
  addAnotherRepresentative: [
    'Добавить ещё представителя',
    'Add another representative',
    '添加另一个代表',
    'Agregar otro representante',
  ],
  representativeList: [
    ['Родственник', 'Relative', '亲属', 'Pariente'],
    ['Другое физическое лицо', 'Another individual', '其他个人', 'Otra persona'],
    ['Индивидуальный предприниматель', 'Sole proprietor / Individual entrepreneur', '个体工商户', 'Propietario único / Empresario individual'],
    ['Юридическое лицо', 'Legal entity', '法人', 'Persona jurídica'],
  ],
  representativeCountList: [
    ['Первый представитель', 'First representative', '第一代表', 'Primer representante'],
    ['Второй представитель', 'Second representative', '第二代表', 'Segundo representante'],
    ['Третий представитель', 'Third representative', '第三代表', 'Tercer representante'],
    ['Четвёртый представитель', 'Fourth representative', '第四代表', 'Cuarto representante'],
    ['Пятый представитель', 'Fifth representative', '第五代表', 'Quinto representante'],
  ],
  whatTrust: ['Что доверяет сделать?', 'What does he trust to do?', '他信任做什么？', '¿Qué confía que haga?'],
  powers: ['Полномочия', 'Authority', '权力', 'Autoridad'],
  powersDescription: [
    'Например, распоряжаться квартирой и машиной, управлять банковскими счетами, получить документы в госучреждениях и поставить на них апостиль',
    'For example, managing an apartment and a car, handling bank accounts, obtaining documents from government agencies, and getting them apostilled.',
    '例如，管理公寓和汽车，处理银行账户，从政府机构获取文件，并进行公证。',
    'Por ejemplo, gestionar un apartamento y un coche, manejar cuentas bancarias, obtener documentos de agencias gubernamentales y apostillarlos.',
  ],
  powerAbove: [
    'Опишите, какие действия вы хотите разрешить производить от вашего лица',
    'Describe which actions you want to authorize on your behalf',
    '描述您希望授权的行为',
    'Describa qué acciones desea autorizar en su nombre',
  ],
  withAtorneyRights: ['С правом передоверия', 'With the right of attorney', '有转授权的权利', 'Con derecho a la delegación'],
  poatValid: [
    'На какой срок оформляется доверенность?',
    'For what term is the Power of Attorney issued?',
    '授权书的有效期是多长？',
    '¿Por cuánto tiempo se emite el poder notarial?',
  ],
  yearsOfPoat: ['Укажите количество лет:', 'Specify the number of years:', '请指定年数：', 'Especifique el número de años:'],
  poatEndDate: [
    'Или точную дату окончания действия доверенности:',
    "Or the exact date of the Power of Attorney's expiration:",
    '或授权书有效期的确切截止日期：',
    'O la fecha exacta de expiración del poder notarial:',
  ],
  endDate: ['Дата окончания', 'End date', '到期日', 'Fecha de finalización'],
  provideContact: [
    'Укажите свои контактные данные',
    'Please provide your contact information',
    '请提供您的联系信息',
    'Por favor, proporcione su información de contacto',
  ],
  userName: ['Имя', 'Name', '姓名', 'Nombre'],
  enterYourName: ['Введите ваше имя', 'Enter your name', '请输入您的姓名', 'Ingrese su nombre'],
  userPhone: ['Телефон', 'Phone', '电话', 'Teléfono'],
  enterYourPhone: ['Введите ваш телефон', 'Enter your phone', '请输入您的电话', 'Ingrese su teléfono'],
  email: ['Электронная почта', 'E-mail', '电子邮件', 'Correo electrónico'],
  imAgree: [
    'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями',
    'I consent to the processing of personal data and agree to the terms of the',
    '我同意处理个人数据并同意条款',
    'Consiento el procesamiento de datos personales y estoy de acuerdo con los términos de la',
  ],
  imAgreePrivicyPolicy: ['политики конфиденциальности', 'privacy policy', '隐私政策', 'política de privacidad'],
  documentUpload: ['Загрузите документы', 'Upload documents', '上传文件', 'Cargar documentos'],
  documentUploadText1: [
    'Ниже приведен примерный список документов, который потребуется предоставить для оформления доверенности.',
    'Below is an approximate list of documents that will be required to issue a Power of Attorney.',
    '以下是办理授权书所需文件的示例列表。',
    'A continuación se muestra una lista aproximada de documentos que serán necesarios para emitir un poder notarial.',
  ],
  documentUploadText2: [
    'Вы можете загрузить их сейчас или отправить юристу после консультации с ним (в таком случае нажмите кнопку Пропустить).',
    'You can upload them now or send them to the lawyer after consulting with him (in this case, click the Skip button).',
    '您可以立即上传这些文件，或者在与律师咨询后发送给他（在这种情况下，请单击跳过按钮）。',
    'Puede cargar estos documentos ahora o enviárselos al abogado después de consultarlo (en este caso, haga clic en el botón Omitir).',
  ],
  documentList: ['Список документов:', 'Document list:', '文件清单：', 'Lista de documentos:'],



 
  documentListArray: [
    {
      passport: [
        'Внутренний паспорт РФ',
        'Internal passport of the Russian Federation',
        '俄罗斯联邦内务护照',
        'Pasaporte interno de la Federación Rusa',
      ],
      desc: [
        'Скан разворота с фото и страницы с регистрацией',
        'A scan of the spread with the photo and the page with the registration.',
        '带照片的展开图及登记页的扫描件',
        'Escaneo de la página con foto y la página de registro.',
      ],
    },
    {
      passport: [
        'Загранпаспорт',
        'International passport',
        '国际护照',
        'Pasaporte internacional',
      ],
      desc: [
        'Скан разворота с фото',
        'A scan of the spread with the photo.',
        '带照片的展开图的扫描件',
        'Escaneo de la página con foto.',
      ],
    },
    {
      passport: [
        'Внутренний паспорт или загранпаспорт представителя',
        'Internal passport or foreign passport of the representative.',
        '代表的内务护照或外国护照',
        'Pasaporte interno o pasaporte extranjero del representante.',
      ],
      desc: [null, null, null, null],
    },
  ],
  
}

export default vocabularyPowerOfAttorney
