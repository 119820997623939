import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import Layout300 from './pages/Layouts/Layout300'
import Main599 from './pages/599/Main599'
import Main600 from './pages/600/Main600'
import Main819 from './pages/819/Main819'
import Main947 from './pages/947/Main947'
import Main1239 from './pages/1239/Main1239'
import Main1439 from './pages/1439/Main1439'
import AboutUs599 from './pages/599/AboutUs599'
import AboutUs600 from './pages/600/AboutUs600'
import AboutUs819 from './pages/819/AboutUs819'
import AboutUs947 from './pages/947/About947'
import AboutUs1239 from './pages/1239/AboutUs1239'
import AboutUs1439 from './pages/1439/AboutUs1439'
import PowerOfAttorney599 from './pages/599/PowerOfAttorney599'
import PowerOfAttorney600 from './pages/600/PowerOfAttorney600'
import PowerOfAttorney819 from './pages/819/PowerOfAttorney819'
import PowerOfAttorney947 from './pages/947/PowerOfAttorney947'
import PowerOfAttorney1239 from './pages/1239/PowerOfAttorney1239'
import PowerOfAttorney1439 from './pages/1439/PowerOfAttorney1439'
import Passport599 from './pages/599/Passport599'
import Passport600 from './pages/600/Passport600'
import Passport819 from './pages/819/Passport819'
import Passport947 from './pages/947/Passport947'
import Passport1239 from './pages/1239/Passport1239'
import Passport1439 from './pages/1439/Passport1439'
import Consent599 from './pages/599/Consent599'
import Consent600 from './pages/600/Consent600'
import Consent819 from './pages/819/Consent819'
import Consent947 from './pages/947/Consent947'
import Consent1239 from './pages/1239/Consent1239'
import Consent1439 from './pages/1439/Consent1439'
import Certificates599 from './pages/599/Certificates599'
import Certificates600 from './pages/600/Certificates600'
import Certificates819 from './pages/819/Certificates819'
import Certificates947 from './pages/947/Certificates947'
import Certificates1239 from './pages/1239/Certificates1239'
import Certificates1439 from './pages/1439/Certificates1439'
import LegalEntities599 from './pages/599/LegalEntities599'
import LegalEntities600 from './pages/600/LegalEntities600'
import LegalEntities819 from './pages/819/LegalEntities819'
import LegalEntities947 from './pages/947/LegalEntities947'
import LegalEntities1239 from './pages/1239/LegalEntities1239'
import LegalEntities1439 from './pages/1439/LegalEntities1439'
import { useContext } from 'react'
import GlobalContext from './GlobalContext'
import Home599 from './pages/599/PrivateScreens/Home599'
import Zags599 from './pages/599/Zags599'
import Zags600 from './pages/600/Zags600'
import Zags819 from './pages/819/Zags819'
import Zags947 from './pages/947/Zags947'
import Zags1239 from './pages/1239/Zags1239'
import Zags1439 from './pages/1439/Zags1439'
import Citizenship599 from './pages/599/Citizenship599'
import Citizenship600 from './pages/600/Citizenship600'
import Citizenship819 from './pages/819/Citizenship819'
import Citizenship947 from './pages/947/Citizenship947'
import Citizenship1239 from './pages/1239/Citizenship1239'
import Citizenship1439 from './pages/1439/Citizenship1439'
import Other599 from './pages/599/Other599'
import Other600 from 'pages/600/Other600'
import Other819 from 'pages/819/Other819'
import Other947 from 'pages/947/Other947'
import Other1239 from 'pages/1239/Other1239'
import Other1439 from 'pages/1439/Other1439'
import Consulting599 from 'pages/599/Consulting599'
import Consulting600 from 'pages/600/Consulting600'
import Consulting819 from 'pages/819/Consulting819'
import Consulting947 from 'pages/947/Consulting947'
import Consulting1239 from 'pages/1239/Consulting1239'
import Consulting1439 from 'pages/1439/Consulting1439'
import Appointment599 from 'pages/599/Appointment599'
import Loading from 'pages/Loading'
import Appointment600 from 'pages/600/Appointment600'
import Appointment819 from 'pages/819/Appointment819'
import Appointment947 from 'pages/947/Appointment947'
import Appointment1239 from 'pages/1239/Appointment1239'
import Appointment1439 from 'pages/1439/Appointment1439'
import FaqPage599 from 'pages/599/FaqPage599'
import FaqPage600 from 'pages/600/FaqPage600'
import FaqPage819 from 'pages/819/FaqPage819'
import FaqPage947 from 'pages/947/FaqPage947'
import FaqPage1239 from 'pages/1239/FaqPage1239'
import FaqPage1439 from 'pages/1439/FaqPage1439'
import Prices599 from 'pages/599/Prices599'
import Prices600 from 'pages/600/Prices600'
import Prices819 from 'pages/819/Prices819'
import Prices947 from 'pages/947/Prices947'
import Prices1239 from 'pages/1239/Prices1239'
import Prices1439 from 'pages/1439/Prices1439'
import Home600 from 'pages/600/PrivateScreens/Home600'
import Home819 from 'pages/819/PrivateScreens/Home819'
import Home947 from 'pages/947/PrivateScreens/Home947'
import Home1239 from 'pages/1239/PrivateScreens/Home1239'
import Home1439 from 'pages/1439/PrivateScreens/Home1439'
import PrivacyPolicy599 from 'pages/599/PrivacyPolicy599'
import PrivacyPolicy600 from 'pages/600/PrivacyPolicy600'
import PrivacyPolicy819 from 'pages/819/PrivacyPolicy819'
import PrivacyPolicy947 from 'pages/947/PrivacyPolicy947'
import PrivacyPolicy1239 from 'pages/1239/PrivacyPolicy1239'
import PrivacyPolicy1439 from 'pages/1439/PrivacyPolicy1439'
import { Request } from 'pages/Request/Request'
import UserAgreement599 from 'pages/599/UserAgreement599'
import UserAgreement600 from 'pages/600/UserAgreement600'
import UserAgreement819 from 'pages/819/UserAgreement819'
import UserAgreement947 from 'pages/947/UserAgreement947'
import UserAgreement1239 from 'pages/1239/UserAgreement1239'
import UserAgreement1439 from 'pages/1439/UserAgreement1439'
import CookiePolicy599 from 'pages/599/CookiePolicy599'
import CookiePolicy600 from 'pages/600/CookiePolicy600'
import CookiePolicy819 from 'pages/819/CookiePolicy819'
import CookiePolicy947 from 'pages/947/CookiePolicy947'
import CookiePolicy1239 from 'pages/1239/CookiePolicy1239'
import CookiePolicy1439 from 'pages/1439/CookiePolicy1439'
import InfoIp from 'pages/InfoIp'
import Orders from 'pages/Orders'
import AdmList from 'pages/components/AdmList'
import PaymentRandom from 'pages/PaymentRandom'
import Users from 'pages/Users'
import PaymentSucsess from 'pages/PaymentSuccess'

export default function Paths() {
  let is599 = useMediaQuery({ minWidth: 278, maxWidth: 599 })
  let is600 = useMediaQuery({ minWidth: 600, maxWidth: 819 })
  const is819 = useMediaQuery({ minWidth: 820, maxWidth: 947 })
  const is947 = useMediaQuery({ minWidth: 948, maxWidth: 1239 })
  const is1239 = useMediaQuery({ minWidth: 1240, maxWidth: 1439 })
  const is1439 = useMediaQuery({ minWidth: 1440 })
  let isNoScreen = useMediaQuery({ maxWidth: 277 })
  const { isLoggedIn, langSelected, state,GlobalActions } = useContext(GlobalContext)

  if (isBrowser && (is599 || is600)) {
    // is599 = false;
    //is600 = false;
    // isNoScreen = true;
  }

  let Main,
    AboutUs,
    PowerOfAttorney,
    Passport,
    Consent,
    Certificates,
    LegalEntities,
    Home,
    Zags,
    Citizenship,
    Other,
    Consulting,
    Appointment,
    Faq,
    Prices,
    UserAgreement,
    CookiePolicy,
    PrivacyPolicy

  Main =
    AboutUs =
    PowerOfAttorney =
    Passport =
    Consent =
    Certificates =
    LegalEntities =
    Home =
    Zags =
    Citizenship =
    Other =
    Consulting =
    Appointment =
    Faq =
    Prices =
    UserAgreement =
    CookiePolicy =
    PrivacyPolicy =
      Layout300

  if (is599) {
    Main = Main599
    PowerOfAttorney = PowerOfAttorney599
    AboutUs = AboutUs599
    Passport = Passport599
    Consent = Consent599
    Certificates = Certificates599
    LegalEntities = LegalEntities599
    Zags = Zags599
    Citizenship = Citizenship599
    Other = Other599
    Consulting = Consulting599
    Appointment = Appointment599
    Faq = FaqPage599
    Prices = Prices599
    Home = Home599
    UserAgreement = UserAgreement599
    CookiePolicy = CookiePolicy599
    PrivacyPolicy = PrivacyPolicy599
  } else if (is600) {
    Main = Main600
    AboutUs = AboutUs600
    PowerOfAttorney = PowerOfAttorney600
    Passport = Passport600
    Consent = Consent600
    Certificates = Certificates600
    LegalEntities = LegalEntities600
    Zags = Zags600
    Citizenship = Citizenship600
    Other = Other600
    Consulting = Consulting600
    Appointment = Appointment600
    Faq = FaqPage600
    Prices = Prices600
    Home = Home600
    CookiePolicy = CookiePolicy600
    UserAgreement = UserAgreement600
    PrivacyPolicy = PrivacyPolicy600
  } else if (is819) {
    Main = Main819
    AboutUs = AboutUs819
    PowerOfAttorney = PowerOfAttorney819
    Passport = Passport819
    Consent = Consent819
    Certificates = Certificates819
    LegalEntities = LegalEntities819
    Zags = Zags819
    Citizenship = Citizenship819
    Other = Other819
    Consulting = Consulting819
    Appointment = Appointment819
    Faq = FaqPage819
    Prices = Prices819
    Home = Home819
    CookiePolicy = CookiePolicy819
    UserAgreement = UserAgreement819
    PrivacyPolicy = PrivacyPolicy819
  } else if (is947) {
    Main = Main947
    AboutUs = AboutUs947
    PowerOfAttorney = PowerOfAttorney947
    Passport = Passport947
    Consent = Consent947
    Certificates = Certificates947
    LegalEntities = LegalEntities947
    Zags = Zags947
    Citizenship = Citizenship947
    Other = Other947
    Consulting = Consulting947
    Appointment = Appointment947
    Faq = FaqPage947
    Prices = Prices947
    Home = Home947
    CookiePolicy = CookiePolicy947
    UserAgreement = UserAgreement947
    PrivacyPolicy = PrivacyPolicy947
  } else if (is1239) {
    Main = Main1239
    AboutUs = AboutUs1239
    PowerOfAttorney = PowerOfAttorney1239
    Passport = Passport1239
    Consent = Consent1239
    Certificates = Certificates1239
    LegalEntities = LegalEntities1239
    Zags = Zags1239
    Citizenship = Citizenship1239
    Other = Other1239
    Consulting = Consulting1239
    Appointment = Appointment1239
    Faq = FaqPage1239
    Prices = Prices1239
    Home = Home1239
    UserAgreement = UserAgreement1239
    CookiePolicy = CookiePolicy1239
    PrivacyPolicy = PrivacyPolicy1239
  } else if (is1439) {
    Main = Main1439
    AboutUs = AboutUs1439
    PowerOfAttorney = PowerOfAttorney1439
    Passport = Passport1439
    Consent = Consent1439
    Certificates = Certificates1439
    LegalEntities = LegalEntities1439
    Zags = Zags1439
    Citizenship = Citizenship1439
    Other = Other1439
    Consulting = Consulting1439
    Appointment = Appointment1439
    Faq = FaqPage1439
    Prices = Prices1439
    Home = Home1439
    UserAgreement = UserAgreement1439
    CookiePolicy = CookiePolicy1439
    PrivacyPolicy = PrivacyPolicy1439
  } else if (isNoScreen) {
    Passport =
      Main =
      AboutUs =
      PowerOfAttorney =
      Zags =
      LegalEntities =
      Certificates =
      Citizenship =
      Other =
      Consulting =
      Appointment =
      Faq =
      PrivacyPolicy =
        Layout300
  }

  return (
    <BrowserRouter>
      <Routes>
        {langSelected || langSelected === 0 ? (
          <>
            {isLoggedIn ? (
              <>
                <Route path='/home' element={<Home />}></Route>
              </>
            ) : null}
            <Route path='/' element={<Main isBrowser={isBrowser} langPath={langSelected} />} />
            <Route path='/en' element={<Main isBrowser={isBrowser} langPath={1} />} />
            <Route path='/cn' element={<Main isBrowser={isBrowser} langPath={2} />} />
            <Route path='/es' element={<Main isBrowser={isBrowser} langPath={3} />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/payments_recieved' element={<PaymentSucsess />} />
            <Route path='/payment-random' element={<PaymentRandom />} />
            <Route path='/poa/*' element={<PowerOfAttorney />} />
            <Route path='/passport/*' element={<Passport />} />
            <Route path='/consent/*' element={<Consent />} />
            <Route path='/certificates/*' element={<Certificates />} />
            <Route path='/legal-entities/*' element={<LegalEntities />} />
            <Route path='/zags/*' element={<Zags />} />
            <Route path='/citizenship/*' element={<Citizenship />} />
            <Route path='/other/*' element={<Other />} />
            <Route path='/consulting/*' element={<Consulting />} />

            <Route path='/appointment' element={<Appointment />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/prices' element={<Prices />} />
            <Route path='/user-agreement' element={<UserAgreement />} />
            <Route path='/cookie-policy' element={<CookiePolicy />} />
            <Route path='/requests/:id' element={<Request />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            {state.userInfo?.role === 'admin' ||
            state.userInfo?.role === 'su' ? (
              <>
                <Route path='/private/orders' element={<Orders />} />
                <Route path='/private/info-ip' element={<InfoIp />} />
                <Route path='/private/users' element={<Users />} />

                {state.userInfo?.role === 'su' ? (
                  <Route path='/private/get-adm-list' element={<AdmList />} />
                ) : null}
              </>
            ) : null}
            <Route path='*' element={<Main isBrowser={isBrowser} />} />
          </>
        ) : (
          <Route path='*' element={<Loading />} />
        )}
      </Routes>
    </BrowserRouter>
  )
}
