const vocabularyUserAgreement = [
  [
    {
      title: 'Общие положения',
      content: [
        '1.1. Компания «ICC Consul» LLP (KZ), далее именуемая «Оператор», предлагает услуги посредством сайта https://consul.online (далее «Сайт»).',
        '1.2. Настоящее Пользовательское соглашение (далее «Договор» или «Оферта»), является официальным предложением Оператора любому Пользователю сайта (далее «Пользователь»), принять его условия для получения доступа к предлагаемым услугам.',
        '1.3. Акцептом (безусловным принятием) условий настоящего Договора (моментом заключения Договора) считается факт оплаты Пользователем услуг, выбранных Пользователем на сайте.',
        '1.4. Осуществляя акцепт настоящей Оферты в порядке, определённом в п. 1.3 настоящего Договора, Пользователь соглашается со всеми условиями Договора в том виде, в каком они изложены в тексте настоящего Договора, а также со всеми условиями реализации функций Оператора.',
        '1.5. Заключая настоящий Договор, Пользователь понимает значение своих действий и способен руководить ими, не находится под влиянием заблуждения, обмана, насилия, угрозы. Договор заключен Пользователем добровольно, с предварительным полным ознакомлением с условиями настоящей Оферты, порядка оказания услуг изложенных на Сайте, содержание которых Пользователю понятно.',
      ],
    },
    {
      title: 'Предмет Договора',
      content: [
        '2.1. Оператор предоставляет Пользователю доступ к сайту для оказания следующих услуг:',
        [
          'подготовка проектов документов для Пользователя и их последующее размещение на консульском портале',
          'запись Пользователя на приём в Консульство',
          'предоставление консультационных услуг, в том числе в целях получения Пользователем консульских услуг',
        ],
        'Под «Консульством» Оператор и Пользователь понимают консульские учреждения Российской Федерации и консульские отделы дипломатических представительств Российской Федерации в стране нахождения Пользователя. Пользователь использует функционал сайта в соответствии с правилами и регуляциями, установленными Оператором.',
        '2.2. Пользователь принимает условия Договора и обязуется их соблюдать.',
        '2.3. Оплата услуги от Пользователя на сайте или любым другим способом является подтверждением его согласия с условиями настоящего Договора. Пользователь, завершивший процесс оплаты, считается принявшим оферту Оператора без каких-либо оговорок.',
        '2.4. Оператор обеспечивает доступ к полному тексту Публичного договора на платформе, который Пользователь обязан прочитать до начала оплаты услуг. Предоставление данных Пользователя Оператору означает полное и безоговорочное принятие Пользователем всех условий настоящего Договора.',
      ],
    },
    {
      title: 'Права и обязанности сторон',
      content: [
        '3.1. Оператор обязуется предоставить доступ к Сайту в рамках технических возможностей и соблюдения конфиденциальности информации Пользователя.',
        '3.2. Пользователь обязуется использовать Сайт только в законных целях и не предпринимать действий, нарушающих работу сайта или права других пользователей.',
      ],
    },
    {
      title: 'Условия использования сайта',
      content: [
        '4.1. Пользователь имеет право на доступ к различным услугам Сайта. Каждый запрос на услуги, предоставляемые через Сайт, рассматривается как акцепт от Пользователя, которую Оператор может принять или отклонить в соответствии с внутренними правилами и доступностью услуг.',
        '4.2. Акцепт Пользователя считается принятым Оператором с момента подтверждения обработки запроса через Сайт. Детали и условия исполнения услуги регулируются спецификацией запроса и подтверждением от Оператора.',
        '4.3. Все права на товарные знаки, авторские права, права на базы данных и другие права интеллектуальной собственности на содержимое Сайта (включая его организацию, макет и исходный код программного обеспечения), принадлежат Оператору. Пользователю запрещается полностью или частично копировать, изменять, распространять, использовать или воспроизводить содержимое Сайта без предварительного письменного разрешения Оператора, кроме случаев личного использования при условии сохранения всех авторских прав и других уведомлений, содержащихся в них.',
        '4.4. Запрещаются любые действия, мешающие работе Сайта или создающие чрезмерную нагрузку на его инфраструктуру, включая загрузку файлов, заражённых вирусами, а также любые попытки получить несанкционированный доступ к технической инфраструктуре Сайта.',
      ],
    },
    {
      title: 'Разрешение споров',
      content: [
        '5.1. Все споры и разногласия, возникающие из данного Договора, стороны стараются решать путём переговоров.',
        '5.2. Если спор не может быть урегулирован переговорами, он подлежит рассмотрению в соответствующем суде по месту регистрации Оператора.',
        '5.3. Оператор не несёт ответственности за последствия, связанные с предоставлением Пользователем недостоверных, искажённых, с истёкшим сроком сведений.',
      ],
    },
    {
      title: 'Права и обязанности Оператора',
      content: [
        '6.1. Оператор при оказании Услуг руководствуется нормативно-правовыми актами в соответствии с действующим применимым законодательством.',
        '6.2. Оператор оказывает Услуги только после проверки всех данных предоставленных Пользователем.',
        '6.3. Оператор может отказать в оказании Услуг в случае непредоставления необходимых данных Пользователем, предусмотренных соответствующим разделом на Сайте.',
        '6.4. Оператор имеет право по своему усмотрению изменять тарифы на оказание Услуг, а также настоящий Договор (Оферту), либо отзывать их в соответствии с условиями настоящего Договора.',
        '6.5. Оператор не оказывает Пользователю Услуги, указанные в заявке, но не оплаченные Пользователем.',
        '6.6. Оператор обязуется соблюдать высокие стандарты профессионализма и этики при оказании услуг и во взаимодействии с Пользователями, а также защищать конфиденциальность и личные данные Пользователей в соответствии с применимым законодательством.',
      ],
    },
    {
      title: 'Заключительные положения',
      content: [
        '7.1. Договор вступает в силу с момента его акцепта Пользователем и действует на неопределённый срок.',
        '7.2. Исполнитель не принимает на себя никаких иных условий, не обозначенных в Договоре.',
      ],
    },
  ],

  [
    {
      title: 'General Provisions',
      content: [
        "1.1. ICC Consul LLP (KZ), hereinafter referred to as the 'Operator', offers services via the https://consul.online website (hereinafter referred to as the 'Website').",
        '1.2. This User Agreement (hereinafter referred to as the Agreement or Offer) is an official offer from the Operator to any User of the Website (hereinafter referred to as the User) to accept its terms in order to gain access to the offered services.',
        '1.3. Acceptance (unconditional acceptance) of the terms of this Agreement (the moment of the conclusion of the Agreement) is considered to be the fact of payment by the User for the services selected by the User on the Website.',
        "1.4. By accepting this Offer in the manner defined in clause 1.3 of this Agreement, the User agrees with all the terms of the Agreement in the form in which they are stated in the text of this Agreement, as well as with all the conditions for the implementation of the Operator's functions.",
        '1.5. By entering into this Agreement, the User understands the significance of their actions and is capable of managing them, is not under the influence of delusion, fraud, violence, threat. The Agreement is concluded by the User voluntarily, with prior full acquaintance with the terms of this Offer, the details of rendering services on the Website, the content of which is clear to the User.',
      ],
    },
    {
      title: 'Subject of the Agreement',
      content: [
        '2.1. The Operator provides the User with access to the Website for the provision of the following services:',
        [
          'preparation of draft documents for the User and their subsequent submission to the consulate portal',
          'scheduling an appointment at the Consulate for the User',
          'provision of consulting services, including those for obtaining consular services by the User',
        ],
        "Under the 'Consulate' the Operator and the User understand the consular institutions of the Russian Federation and the consular departments of the diplomatic missions of the Russian Federation in the country of residence of the User. The User uses the functionality of the Website in accordance with the rules and regulations established by the Operator.",
        '2.2. The User accepts the terms of the Agreement and undertakes to comply with them.',
        '2.3. Payment for the service by the User on the Website or by any other method is confirmation of their agreement with the terms of this Agreement. The User who has completed the payment process is considered to have accepted the Operator’s offer without any reservations.',
        '2.4. The Operator provides access to the full text of the Public Agreement on the platform, which the User is obliged to read before starting payment for services. Providing the User’s data to the Operator means the User’s full and unconditional acceptance of all the terms of this Agreement.',
      ],
    },
    {
      title: 'Rights and Obligations of the Parties',
      content: [
        '3.1. The Operator undertakes to provide access to the Website within the framework of technical capabilities and to ensure the confidentiality of the User’s information.',
        '3.2. The User undertakes to use the Website only for lawful purposes and not to take actions that violate the operation of the Website or the rights of other Users.',
      ],
    },
    {
      title: 'Terms of Use of the Website',
      content: [
        '4.1. The User has the right to access various services of the Website. Each request for services provided through the Website is considered as acceptance from the User, which the Operator may accept or reject according to internal rules and availability of services.',
        '4.2. The User’s acceptance is considered accepted by the Operator from the moment of confirming the processing of the request through the Website. The details and conditions for fulfilling the service are regulated by the specifications of the request and confirmation from the Operator.',
        '4.3. All rights to trademarks, copyrights, database rights, and other intellectual property rights to the content of the Website (including its organization, layout, and source code of software) belong to the Operator. The User is prohibited from fully or partially copying, modifying, distributing, using, or reproducing the content of the Website without the prior written permission of the Operator, except in cases of personal use while preserving all copyrights and other notices contained therein.',
        '4.4. Any actions that hinder the operation of the Website or create excessive load on its infrastructure, including uploading files infected with viruses, as well as any attempts to gain unauthorized access to the technical infrastructure of the Website, are prohibited.',
      ],
    },
    {
      title: 'Dispute Resolution',
      content: [
        '5.1. All disputes and disagreements arising from this Agreement are attempted to be resolved through negotiations.',
        '5.2. If the dispute cannot be resolved through negotiations, it is subject to consideration in the competent court at the location of the Operator’s registration.',
        '5.3. The Operator is not responsible for the consequences related to the provision of unreliable, distorted, or expired information by the User.',
      ],
    },
    {
      title: 'Operator’s Rights and Obligations',
      content: [
        '6.1. The Operator, when providing Services, is guided by regulatory legal acts in accordance with the current applicable legislation.',
        '6.2. The Operator provides Services only after verifying all data provided by the User.',
        '6.3. The Operator may refuse to provide Services in case of non-provision of the necessary data by the User as stipulated in the relevant section of the Website.',
        '6.4. The Operator has the right at its discretion to change the rates for providing Services, as well as this Agreement (Offer), or revoke them in accordance with the terms of this Agreement.',
        '6.5. The Operator does not provide the User with Services indicated in the application but not paid for by the User.',
        '6.6. The Operator undertakes to adhere to high standards of professionalism and ethics in providing services and in interacting with Users, as well as to protect the confidentiality and personal data of Users in accordance with applicable law.',
      ],
    },
    {
      title: 'Final Provisions',
      content: [
        '7.1. The Agreement comes into force from the moment of its acceptance by the User and is valid for an indefinite period.',
        '7.2. The Contractor does not accept any other conditions not stated in the Agreement.',
      ],
    },
  ],

  [
    {
      title: '一般条款',
      content: [
        "1.1. ICC Consul LLP（KZ），以下简称为“运营商”，通过网站 https://consul.online 提供服务（以下简称为“网站”）。",
        '1.2. 本用户协议（以下简称为“协议”或“要约”）是运营商向任何网站用户（以下简称为“用户””）的正式提议，以接受其条款以获得所提供服务的访问权限。',
        '1.3. 本协议的接受（无条件接受）（协议签署的时刻）被认为是用户在网站上选择的服务付款的事实。',
        "1.4. 通过按照本协议第1.3条规定的方式接受本要约，用户同意以本协议文本中规定的形式接受本协议的所有条款，以及所有实施运营商职能的条件。",
        '1.5. 签署本协议时，用户理解其行为的意义并能够管理，未受到误解、欺诈、暴力、威胁的影响。用户自愿签署协议，事先完全了解本要约的条款，以及网站上提供服务的内容，用户对此内容清楚明白。',
      ],
    },
    {
      title: '协议的主题',
      content: [
        '2.1. 运营商为用户提供访问网站以提供以下服务：',
        [
          '为用户准备文档草稿，并将其随后提交到领事门户',
          '为用户在领事馆预约',
          '提供咨询服务，包括为用户获取领事服务的目的',
        ],
        "在这里，运营商和用户理解，领事馆是指俄罗斯联邦的领事机构和俄罗斯联邦在用户所在国家的外交代表处的领事部门。用户根据运营商制定的规则和规定使用网站功能。",
        '2.2. 用户接受本协议的条款并承担遵守义务。',
        '2.3. 用户在网站上或以任何其他方式支付服务费用是其同意本协议条款的确认。完成付款过程的用户被视为无保留地接受运营商的要约。',
        '2.4. 运营商在平台上提供公共协议的完整文本，用户在开始支付服务之前有义务阅读。将用户数据提供给运营商意味着用户完全无条件接受本协议的所有条款。',
      ],
    },
    {
      title: '各方的权利和义务',
      content: [
        '3.1. 运营商在技术能力范围内承诺提供对网站的访问，并确保用户信息的保密性。',
        '3.2. 用户承诺仅将网站用于合法目的，不得采取违反网站操作或其他用户权利的行为。',
      ],
    },
    {
      title: '网站使用条款',
      content: [
        '4.1. 用户有权访问网站的各种服务。通过网站提供的每个服务请求被视为用户的接受，运营商可以根据内部规则和服务的可用性接受或拒绝该请求。',
        '4.2. 从运营商确认通过网站处理请求的那一刻起，用户的接受被视为运营商接受。服务执行的细节和条件由请求的规格和运营商的确认进行调整。',
        '4.3. 网站内容（包括其组织、布局和软件源代码）的商标权、版权、数据库权和其他知识产权归运营商所有。未经运营商事先书面许可，用户不得全部或部分复制、修改、分发、使用或再现网站内容，但在保留所有版权和其他通知的情况下，可以出于个人用途。',
        '4.4. 禁止任何妨碍网站操作或对其基础设施造成过度负担的行为，包括上传受病毒感染的文件，以及尝试未经授权访问网站的技术基础设施。',
      ],
    },
    {
      title: '争议解决',
      content: [
        '5.1. 由于本协议而产生的所有争议和分歧应尽量通过谈判解决。',
        '5.2. 如果争议无法通过谈判解决，则应提交给运营商注册地点的主管法院进行审理。',
        '5.3. 运营商对用户提供的不真实、失真或过期信息所造成的后果不承担任何责任。',
      ],
    },
    {
      title: '运营商的权利和义务',
      content: [
        '6.1. 运营商在提供服务时应遵循适用法律的相关法律法规。',
        '6.2. 运营商仅在验证用户提供的所有数据后才提供服务。',
        '6.3. 若用户未按照网站相关部分提供必要的数据，运营商有权拒绝提供服务。',
        '6.4. 运营商有权自行决定更改服务费率以及本协议（要约），或按照本协议的条款撤回服务。',
        '6.5. 运营商不向用户提供未支付的申请中列示的服务。',
        '6.6. 运营商承诺在提供服务和与用户互动时遵循高标准的专业精神和道德，并根据适用法律保护用户的保密性和个人数据。',
      ],
    },
    {
      title: '最终条款',
      content: [
        '7.1. 协议自用户接受之时起生效，有效期为无期限。',
        '7.2. 承包商不接受协议中未列明的任何其他条件。',
      ],
    },
  ]

 ,[
      {
        "title": "Términos Generales",
        "content": [
          "1.1. ICC Consul LLP (KZ), en adelante denominado 'el Operador', proporciona servicios a través del sitio web https://consul.online (en adelante denominado 'el Sitio').",
          "1.2. Este Acuerdo de Usuario (en adelante denominado 'el Acuerdo' o 'la Oferta') es una propuesta formal del Operador para cualquier usuario del sitio web (en adelante denominado 'el Usuario') para aceptar sus términos y obtener acceso a los servicios proporcionados.",
          "1.3. La aceptación de este Acuerdo (aceptación incondicional) (momento de la firma del Acuerdo) se considera el hecho de que el Usuario paga por el servicio seleccionado en el sitio web.",
          "1.4. Al aceptar esta Oferta de la manera especificada en el párrafo 1.3 de este Acuerdo, el Usuario acepta todos los términos de este Acuerdo en la forma establecida en este texto, así como todas las condiciones para la implementación de las funciones del Operador.",
          "1.5. Al firmar este Acuerdo, el Usuario entiende el significado de sus acciones y puede gestionarlas, sin estar bajo la influencia de malentendidos, engaños, violencia o amenazas. El Usuario firma este Acuerdo de forma voluntaria, habiendo comprendido completamente los términos de esta Oferta, así como el contenido de los servicios proporcionados en el sitio web."
        ]
      },
      {
        "title": "El Objeto del Acuerdo",
        "content": [
          "2.1. El Operador proporciona al Usuario acceso al Sitio para ofrecer los siguientes servicios:",
          [
            "Preparación de borradores de documentos para el Usuario y su posterior presentación en el portal consular.",
            "Reservas para el Usuario en el consulado.",
            "Servicios de consultoría, incluyendo la obtención de servicios consulares para el Usuario."
          ],
          "Aquí, el Operador y el Usuario entienden que el consulado se refiere a las instituciones consulares de la Federación de Rusia y a las representaciones diplomáticas de la Federación de Rusia en el país donde se encuentra el Usuario. El Usuario utiliza las funciones del sitio de acuerdo con las reglas y regulaciones establecidas por el Operador.",
          "2.2. El Usuario acepta los términos de este Acuerdo y se compromete a cumplir con ellos.",
          "2.3. El pago por el servicio por parte del Usuario en el Sitio o por cualquier otro método es la confirmación de su acuerdo con los términos de este Acuerdo. Se considera que el Usuario que ha completado el proceso de pago ha aceptado la oferta del Operador sin reservas.",
          "2.4. El Operador proporciona acceso al texto completo del Acuerdo Público en la plataforma, que el Usuario está obligado a leer antes de iniciar el pago por los servicios. Proporcionar los datos del Usuario al Operador significa que el Usuario acepta incondicionalmente todos los términos de este Acuerdo."
        ]
      },
      {
        "title": "Derechos y Obligaciones de las Partes",
        "content": [
          "3.1. El Operador se compromete a proporcionar acceso al Sitio dentro de las capacidades técnicas y a garantizar la confidencialidad de la información del Usuario.",
          "3.2. El Usuario se compromete a utilizar el Sitio solo para fines legales y no realizar acciones que violen el funcionamiento del Sitio o los derechos de otros Usuarios."
        ]
      },
      {
        "title": "Términos de Uso del Sitio",
        "content": [
          "4.1. El Usuario tiene derecho a acceder a varios servicios del Sitio. Cada solicitud de servicios proporcionados a través del Sitio se considera una aceptación por parte del Usuario, que el Operador puede aceptar o rechazar de acuerdo con las reglas internas y la disponibilidad de servicios.",
          "4.2. La aceptación del Usuario se considera aceptada por el Operador desde el momento de la confirmación del procesamiento de la solicitud a través del Sitio. Los detalles y condiciones para la ejecución del servicio están regulados por las especificaciones de la solicitud y la confirmación del Operador.",
          "4.3. Todos los derechos sobre marcas comerciales, derechos de autor, derechos de base de datos y otros derechos de propiedad intelectual sobre el contenido del Sitio (incluida su organización, diseño y código fuente de software) pertenecen al Operador. El Usuario tiene prohibido copiar, modificar, distribuir, usar o reproducir total o parcialmente el contenido del Sitio sin el permiso previo por escrito del Operador, excepto en casos de uso personal, preservando todos los derechos de autor y otras notificaciones contenidas en el mismo.",
          "4.4. Se prohíben acciones que obstaculicen el funcionamiento del Sitio o que creen una carga excesiva sobre su infraestructura, incluida la carga de archivos infectados con virus, así como cualquier intento de acceder sin autorización a la infraestructura técnica del Sitio."
        ]
      },
      {
        "title": "Resolución de Disputas",
        "content": [
          "5.1. Todas las disputas y desacuerdos que surjan de este Acuerdo se intentan resolver a través de negociaciones.",
          "5.2. Si la disputa no se puede resolver a través de negociaciones, se someterá a consideración en el tribunal competente en la ubicación del registro del Operador.",
          "5.3. El Operador no es responsable de las consecuencias relacionadas con la provisión de información poco fiable, distorsionada o caducada por parte del Usuario."
        ]
      },
      {
        "title": "Derechos y Obligaciones del Operador",
        "content": [
          "6.1. El Operador, al proporcionar Servicios, se guía por los actos legales normativos de acuerdo con la legislación vigente aplicable.",
          "6.2. El Operador proporciona Servicios solo después de verificar todos los datos proporcionados por el Usuario.",
          "6.3. El Operador puede negarse a proporcionar Servicios en caso de no proporcionar los datos necesarios por parte del Usuario según lo estipulado en la sección relevante del Sitio.",
          "6.4. El Operador tiene derecho a cambiar las tarifas por la provisión de Servicios, así como este Acuerdo (Oferta), o revocarlos de acuerdo con los términos de este Acuerdo.",
          "6.5. El Operador no proporciona al Usuario Servicios que se indiquen en la solicitud pero que no hayan sido pagados por el Usuario.",
          "6.6. El Operador se compromete a adherirse a altos estándares de profesionalismo y ética en la prestación de servicios y en la interacción con los Usuarios, así como a proteger la confidencialidad y los datos personales de los Usuarios de acuerdo con la legislación aplicable."
        ]
      },
      {
        "title": "Disposiciones Finales",
        "content": [
          "7.1. El Acuerdo entra en vigor desde el momento de su aceptación por el Usuario y es válido por tiempo indefinido.",
          "7.2. El Contratista no acepta ninguna otra condición no especificada en el Acuerdo."
        ]
      }
    ],
]
export default vocabularyUserAgreement
