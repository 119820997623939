import { FileText, Buildings, Stamp, Bank, Scales } from '@phosphor-icons/react'
import passportImage from '../images/passport-black.png'
import marriageRingsImage from '../images/marriage-black.png'

const vocabularyPrices = {
  pricesAndTariffs: [
    'Цены и тарифы',
    'Prices and tariffs',
    '价格和收费',
    'Precios y tarifas',
  ],
  selectService: [
    'Выберите услугу:',
    'Select service:',
    '选择服务：',
    'Seleccionar servicio:',
  ],
  typesOfPrice: [
    ['Доверенности', 'Powers of Attorney', '授权书', 'Poderes notariales'],
    ['Загранпаспорт', 'Passport (foreign)', '护照（国外）', 'Pasaporte (extranjero)'],
    ['Согласия, заявления', 'Consents, Statements', '同意书，声明', 'Consentimientos, declaraciones'],
    ['Справки', 'Certificates', '证书', 'Certificados'],
    ['Документы для юр. лиц', 'Documents for Legal Entities', '法人文件', 'Documentos para personas jurídicas'],
    ['Гражданство', 'Citizenship', '公民身份', 'Ciudadanía'],
    ['Услуги ЗАГС', 'Registry Office Services', '注册处服务', 'Servicios de registro civil'],
    ['Другие консульские услуги', 'Other Consular Services', '其他领事服务', 'Otros servicios consulares'],
    ['Консультации', 'Consultation', '咨询', 'Consulta'],
  ],
  allServices: [
    'Все услуги',
    'All services',
    '所有服务',
    'Todos los servicios',
  ],
  icons: [
    <FileText size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <FileText size={24} weight='light' />,
    <Stamp size={24} weight='light' />,
    <Buildings size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <img
      src={marriageRingsImage}
      alt='marriage rings'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <Bank size={24} weight='light' />,
    <Scales size={24} weight='light' />,
  ],
};


export default vocabularyPrices
