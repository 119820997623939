import starImage from '../images/star.png'
import shieldImage from '../images/shield.png'
import handsImage from '../images/hands.png'
import clockImage from '../images/clock.png'

const vocabularyAboutUs = {
  // About us
  aboutUsSection: ['о нас', 'about us', '关于我们', 'sobre nosotros'],
  aboutUsSection1: [
    'Consul online - это объединение профессионалов единомышленников, международных юристов с опытом работы российскими консульскими учреждениями и с иностранными правовыми организациями.',
    'Consul.online is an association of like-minded professionals, international lawyers with experience of working with Russian consular institutions and foreign legal organizations.',
    'Consul.online 是一个志同道合的专业人士、国际律师的协会，拥有与俄罗斯领事机构和外国法律组织合作的经验。',
    'Consul.online es una asociación de profesionales afines, abogados internacionales con experiencia en trabajar con instituciones consulares rusas y organizaciones legales extranjeras.',
  ],
  aboutUsSection2: [
    'Наши юристы подготовят документы в том формате, который требуется для подачи в консульство или посольство РФ, а также учитывает требования тех российских организаций, в которых эти документы впоследствии могут быть предоставлены.',
    'Our lawyers will prepare documents in the format required for their submission to the Russian consulate or embassy, taking into account the requirements of the Russian organizations to which these documents may subsequently be submitted.',
    '我们的律师将准备以提交给俄罗斯领事馆或大使馆所需的格式的文件，同时考虑到这些文件可能随后提交给的俄罗斯组织的要求。',
    'Nuestros abogados prepararán los documentos en el formato requerido para su presentación en el consulado o embajada rusa, teniendo en cuenta los requisitos de las organizaciones rusas a las que estos documentos pueden ser presentados posteriormente.',
  ],
  aboutUsSection3: [
    'Мы работаем за пределами России и понимаем с какими проблемами сталкиваются граждане России за рубежом.',
    'We operate outside of Russia and understand the challenges faced by Russian citizens abroad.',
    '我们在俄罗斯境外工作，了解俄罗斯公民在海外面临的挑战。',
    'Operamos fuera de Rusia y entendemos los desafíos que enfrentan los ciudadanos rusos en el extranjero.',
  ],
  aboutUsSection4: [
    'Этот проект создан для помощи нашим соотечественникам, проживающим по всему миру, а также гражданам других стран желающих получить юридическую услугу в рамках российского законодательства на территории иностранных государств без необходимости посещения РФ.',
    'This project has been created to assist our compatriots living around the world, as well as citizens of other countries who wish to obtain legal services within the framework of Russian legislation in foreign countries without the need to visit Russia.',
    '这个项目旨在帮助我们在全球各地生活的同胞，以及希望在外国境内根据俄罗斯法律获得法律服务而不需要访问俄罗斯的其他国家公民。',
    'Este proyecto ha sido creado para asistir a nuestros compatriotas que viven en todo el mundo, así como a los ciudadanos de otros países que deseen obtener servicios legales dentro del marco de la legislación rusa en el extranjero sin necesidad de visitar Rusia.',
  ],
  whyChooseUs: ['Почему выбирают нас?', 'Why choose us?', '为什么选择我们？', '¿Por qué elegirnos?'],
  serviceProcess: [
    [
      {
        headerText: 'Профессионализм',
        cardText: '15 лет опыта работы в юриспруденции, включая взаимодействие с Российскими консульствами',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: 'Доверие',
        cardText: 'Мы строго соблюдаем требования законодательства по работе с персональными данными.',
        about: true,
        cardImage: shieldImage,
        details: 'Подробнее',
      },
      {
        headerText: 'Ответственность',
        cardText: 'В случае отказа в приёме документов в консульстве мы вернём вам деньги или повторно окажем услугу бесплатно',
        about: true,
        cardImage: handsImage,
        // details: 'Подробнее',
      },
      {
        headerText: 'Оперативность',
        cardText: 'Срок подготовки документа — 2 рабочих дня с момента оплаты',
        about: true,
        cardImage: clockImage,
      },
    ],
    [
      {
        headerText: 'Professionalism',
        cardText: '15 years of experience in jurisprudence, including interaction with Russian consulates',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: 'Trust',
        cardText: 'We strictly comply with the requirements of legislation regarding the handling of personal data.',
        about: true,
        cardImage: shieldImage,
        details: 'Details',
      },
      {
        headerText: 'Responsible approach',
        cardText: 'In case of refusal to accept documents at the consulate, we will refund your money or provide the service again free of charge',
        about: true,
        cardImage: handsImage,
        // details: 'Details',
      },
      {
        headerText: 'Efficiency',
        cardText: 'Document preparation time - is 2 business days from the moment of payment',
        about: true,
        cardImage: clockImage,
      },
    ],
    [
      {
        headerText: '专业性',
        cardText: '在法律领域有15年的工作经验，包括与俄罗斯领事馆的互动',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: '信任',
        cardText: '我们严格遵守关于个人数据处理的法律要求。',
        about: true,
        cardImage: shieldImage,
        details: '详细信息',
      },
      {
        headerText: '负责任的态度',
        cardText: '如果在领事馆拒绝接受文件，我们将退款或免费再次提供服务。',
        about: true,
        cardImage: handsImage,
      },
      {
        headerText: '效率',
        cardText: '文件准备时间 - 从付款之日起2个工作日',
        about: true,
        cardImage: clockImage,
      },
    ],
    [
      {
        headerText: 'Profesionalismo',
        cardText: '15 años de experiencia en jurisprudencia, incluyendo interacción con consulados rusos',
        about: true,
        cardImage: starImage,
      },
      {
        headerText: 'Confianza',
        cardText: 'Cumplimos estrictamente con los requisitos de la legislación en el manejo de datos personales.',
        about: true,
        cardImage: shieldImage,
        details: 'Detalles',
      },
      {
        headerText: 'Enfoque responsable',
        cardText: 'En caso de negativa a aceptar documentos en el consulado, reembolsaremos su dinero o prestaremos el servicio nuevamente sin cargo',
        about: true,
        cardImage: handsImage,
      },
      {
        headerText: 'Eficiencia',
        cardText: 'El tiempo de preparación del documento es de 2 días hábiles a partir del momento del pago',
        about: true,
        cardImage: clockImage,
      },
    ],
  ],
  ourMission: [
    'Наша миссия — упростить процесс получения российских государственных услуг за пределами России.',
    'Our mission is to simplify the process of obtaining Russian government services outside of Russia.',
    '我们的使命是简化俄罗斯政府服务在国外的获取过程。',
    'Nuestra misión es simplificar el proceso de obtención de servicios gubernamentales rusos fuera de Rusia.',
  ],
  faq: ['Часто задаваемые вопросы', 'Frequently asked questions', '常见问题', 'Preguntas frecuentes'],
  faqDesc: [
    [
      {
        question: 'В каких странах вы оказываете свои услуги?',
        answer: 'Мы оказываем наши услуги во всех странах мира, где есть действующее консульство/посольство России.',
      },
      {
        question: 'Какие услуги вы предоставляете?',
        answer: 'Мы оказываем услуги по подготовке документов для подачи в Российские консульства/посольства, включая оформление нотариальных документов, загранпаспортов, гражданства России, проверку принадлежности к гражданству РФ, получение справок и услуг ЗАГС.',
      },
      {
        question: 'Я уже записался в консульство, но не успел подготовить проект документа, вы можете мне помочь?',
        answer: 'Да, мы подготовим проект нотариального документа и разместим его на сайте нужного вам консульства.',
      },
      {
        question: 'У меня все готово, мне нужна только запись в консульство, можете записать?',
        answer: 'Нет, мы не оказываем отдельной услуги по записи в консульство / посольство России.',
      },
      {
        question: 'Я уже подготовил все документы, можете ли вы мне помочь с их проверкой и взаимодействием с консульством?',
        answer: 'Да, конечно, мы проверим подготовленный вами проект документа, при необходимости внесём исправления, разместим его на сайте нужного вам консульства и постараемся помочь с записью на приём.',
      },
      {
        question: 'Как вы принимаете оплату за услуги?',
        answer: 'Любую услугу можно оплатить онлайн на сайте. Оплата принимается международными банковскими картами, а также с помощью Apple Pay и Google Pay. Оплата российскими банковскими картами, с использованием системы PayPal или в криптовалюте на сайте не производится, но возможна по запросу.',
      },
    ],
    [
      {
        question: 'In which countries do you provide your services?',
        answer: 'We provide our services in all countries where there is an active Russian consulate/embassy.',
      },
      {
        question: 'What services do you offer?',
        answer: 'We provide services for preparing documents for submission to Russian consulates/embassies, including notarized documents, passports, Russian citizenship, verification of Russian citizenship, obtaining certificates and services from the Registrar.',
      },
      {
        question: "I have already scheduled an appointment at the consulate, but I haven't had time to prepare the document draft. Can you help me?",
        answer: 'Yes, we will prepare a draft notarial document and publish it on the website of the consulate you need.',
      },
      {
        question: 'I have everything ready, I just need an appointment at the consulate, can you schedule it?',
        answer: 'No, we do not provide a separate service for scheduling appointments at the Russian consulate/embassy.',
      },
      {
        question: 'I have prepared all the documents, can you help me with their verification and interaction with the consulate?',
        answer: 'Yes, of course, we will check the document draft prepared by you, make corrections if necessary, publish it on the website of the consulate you need, and try to help with scheduling an appointment.',
      },
      {
        question: 'How do you accept payment for services?',
        answer: 'Any service can be paid online on the website. Payment is accepted by international bank cards, as well as with Apple Pay and Google Pay. Payment with Russian bank cards, PayPal, or in cryptocurrency is not processed on the website, but is possible upon request.',
      },
    ],
    [
      {
        question: '你们在什么国家提供服务？',
        answer: '我们在所有有俄罗斯领事馆/大使馆的国家提供服务。',
      },
      {
        question: '你们提供什么服务？',
        answer: '我们提供为向俄罗斯领事馆/大使馆提交文件而准备的服务，包括公证文件、护照、俄罗斯国籍、俄罗斯国籍的验证、获取证明和登记处的服务。',
      },
      {
        question: '我已经在领事馆预约，但还没有时间准备文件草稿。你们能帮我吗？',
        answer: '是的，我们将准备公证文件草稿，并将其发布在您所需的领事馆网站上。',
      },
      {
        question: '我一切都准备好了，只需要在领事馆的预约，你能帮我吗？',
        answer: '不，我们不提供单独的预约俄罗斯领事馆/大使馆的服务。',
      },
      {
        question: '我已经准备好所有文件，你能帮我检查并与领事馆互动吗？',
        answer: '当然可以，我们将检查您准备的文件草稿，如有必要会进行修改，并将其发布在您所需的领事馆网站上，努力帮助您预约。',
      },
      {
        question: '你们如何接受服务费用的支付？',
        answer: '任何服务都可以在网站上在线支付。接受国际银行卡支付，以及使用Apple Pay和Google Pay。网站上不处理俄罗斯银行卡、PayPal或加密货币的支付，但可以根据要求进行。',
      },
    ],
    [
      {
        question: '¿En qué países ofrecen sus servicios?',
        answer: 'Ofrecemos nuestros servicios en todos los países donde hay un consulado/embajada rusa activa.',
      },
      {
        question: '¿Qué servicios ofrecen?',
        answer: 'Ofrecemos servicios para preparar documentos para su presentación a consulados/embajadas rusas, incluyendo documentos notariales, pasaportes, ciudadanía rusa, verificación de ciudadanía rusa, obtención de certificados y servicios del Registro Civil.',
      },
      {
        question: 'Ya tengo una cita en el consulado, pero no he tenido tiempo de preparar el borrador del documento, ¿pueden ayudarme?',
        answer: 'Sí, prepararemos un borrador del documento notarial y lo publicaremos en el sitio web del consulado que necesites.',
      },
      {
        question: 'Tengo todo listo, solo necesito una cita en el consulado, ¿pueden agendarla?',
        answer: 'No, no ofrecemos un servicio separado para programar citas en el consulado/embajada rusa.',
      },
      {
        question: 'He preparado todos los documentos, ¿pueden ayudarme con su verificación y con la interacción con el consulado?',
        answer: 'Sí, por supuesto, revisaremos el borrador del documento que preparaste, haremos correcciones si es necesario, lo publicaremos en el sitio web del consulado que necesites y trataremos de ayudar con la programación de una cita.',
      },
      {
        question: '¿Cómo aceptan el pago por los servicios?',
        answer: 'Cualquier servicio se puede pagar en línea en el sitio web. Aceptamos pagos con tarjetas bancarias internacionales, así como con Apple Pay y Google Pay. No se procesan pagos con tarjetas bancarias rusas, PayPal o en criptomonedas en el sitio web, pero es posible bajo solicitud.',
      },
    ],
  ],
  askQuestion: ['Задать вопрос', 'Ask a question', '提问', 'Hacer una pregunta'],
};

export default vocabularyAboutUs
