export const vocabulary = {


  title: [
    'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ',
    'PRIVACY POLICY',
    '隐私政策',
    'POLÍTICA DE PRIVACIDAD',
  ],
  pageContent: [
    { type: 'title', text: ['1. Общие положения', '1. General Provisions', '1. 一般规定', '1. Disposiciones generales'] },
    {
      type: 'paragraph',
      text: [
        'Настоящая политика обработки персональных данных составлена в соответствии с требованиями закона Республики Казахстан от 21 мая 2013 года № 94–V «О персональных данных и их защите» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных Компания «ICC Consul» LLP (KZ) (далее — Оператор):',
        'Their personal data processing policy has been drawn up in accordance with the requirements of the Law of the Republic of Kazakhstan dated May 21, 2013 No. 94-V “On Personal Data and Their Protection” and defines the procedure for processing personal data and measures to ensure the security of personal data “of ICC Consul” LLP (KZ) (hereinafter referred to as the Operator):',
        '本个人数据处理政策是根据哈萨克斯坦共和国2013年5月21日第94-V号法律《个人数据及其保护的规定》的要求制定的，规定了个人数据处理的程序和确保个人数据安全的措施“ICC Consul”有限责任公司（KZ）（以下简称“运营商”）：',
        'La presente política de procesamiento de datos personales se ha elaborado de acuerdo con los requisitos de la Ley de la República de Kazajistán del 21 de mayo de 2013, Nº 94-V “Sobre Datos Personales y su Protección”, y define el procedimiento para el procesamiento de datos personales y las medidas para garantizar la seguridad de los datos personales de “ICC Consul” LLP (KZ) (en adelante, el Operador):',
      ],
    },
    { type: 'paragraph', text: ['БИН: 240340024145', 'BIN: 240340024145', 'BIN：240340024145', 'BIN: 240340024145'] },
    {
      type: 'paragraph',
      text: [
        'Юридический адрес: Казахстан, город Астана, район Сарыарка, ул Бейбитшилик, зд. 14, офис 1507',
        'Legal address: 14, Beibitshilik str., office 1507, Saryarka district, Astana,Kazakhstan',
        '法律地址：哈萨克斯坦，阿斯塔纳市，萨里亚尔卡区，贝比特西利克街14号，1507办公室',
        'Dirección legal: 14, Beibitshilik str., oficina 1507, distrito Saryarka, Astana, Kazajistán',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Телефон: +48 698 143 637',
        'Phone: +48 698 143 637',
        '电话：+48 698 143 637',
        'Teléfono: +48 698 143 637',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.',
        'The Operator sets its primary goal and condition for its activities to comply with the rights and freedoms of individuals and citizens when processing their personal data, including protecting the rights to privacy, personal and family secrets.',
        '运营商设定的首要目标和活动条件是遵守个人和公民在处理其个人数据时的权利和自由，包括保护隐私权、个人和家庭秘密的权利。',
        'El Operador establece como objetivo principal y condición de su actividad el cumplimiento de los derechos y libertades de las personas y ciudadanos al procesar sus datos personales, incluida la protección de los derechos a la privacidad, secretos personales y familiares.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях сайта https://consul.online (далее — Сайт).',
        "This Operator's policy regarding the processing of personal data (hereinafter referred to as the Policy) applies to all information that the Operator can receive about visitors to the https://consul.online Website (hereinafter referred to as the Website).",
        '本运营商的个人数据处理政策（以下简称“政策”）适用于运营商可以获取的关于访问者的信息，网址为https://consul.online（以下简称“网站”）。',
        'La presente política del Operador en relación con el procesamiento de datos personales (en adelante, la Política) se aplica a toda la información que el Operador puede recibir sobre los visitantes del sitio web https://consul.online (en adelante, el Sitio).',
      ],
    },
    {
      type: 'title',
      text: ['2. Термины и определения', '2. Terms and Definitions', '2. 术语和定义', '2. Términos y definiciones'],
    },
    {
      type: 'paragraph',
      text: [
        'Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;',
        'Automated processing of personal data — processing of personal data using computing technology;',
        '自动化处理个人数据——使用计算技术处理个人数据；',
        'Procesamiento automatizado de datos personales: procesamiento de datos personales utilizando tecnología informática;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);',
        'Blocking of personal data — temporary cessation of personal data processing (except for in cases where processing is necessary to clarify personal data);',
        '个人数据的阻止——暂时停止处理个人数据（除非处理是为澄清个人数据所必需的）；',
        'Bloqueo de datos personales: cese temporal del procesamiento de datos personales (excepto en los casos en que el procesamiento sea necesario para aclarar los datos personales);',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу https://consul.online;',
        'Website — a collection of graphic and informational materials, as well as software for computers and databases that ensure their availability on the Internet at the network address https://consul.online;',
        '网站——图形和信息材料的集合，以及确保它们在https://consul.online网络地址的可用性的计算机软件和数据库；',
        'Sitio web: una colección de materiales gráficos e informativos, así como software para computadoras y bases de datos que aseguran su disponibilidad en Internet en la dirección de red https://consul.online;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;',
        'Information system of personal data — a set of personal data contained in databases and ensuring their processing by information technologies and technical means;',
        '个人数据的信息系统——包含在数据库中的个人数据的集合，并确保通过信息技术和技术手段处理这些数据；',
        'Sistema de información de datos personales: un conjunto de datos personales contenidos en bases de datos que aseguran su procesamiento mediante tecnologías de información y medios técnicos;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Консульство — консульские учреждения Российской Федерации и консульские отделы дипломатических представительств Российской Федерации в стране нахождения Пользователя;',
        "Consulate — consular institutions of the Russian Federation and consular departments of the diplomatic missions of the Russian Federation in the User's country;",
        '领事馆——俄罗斯联邦的领事机构和俄罗斯联邦驻用户所在国的外交代表机构的领事部门；',
        'Consulado: instituciones consulares de la Federación Rusa y departamentos consulares de las misiones diplomáticas de la Federación Rusa en el país del Usuario;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Консульский портал — официальный информационный интернет-портал Консульского департамента Министерства иностранных дел Российской Федерации, включающий в себя https://www.kdmid.ru/, https://www.mid.ru/, https://zp.midpass.ru/ и их поддомены',
        'Consulate portal — the official information internet portal of the Consular Department of the Ministry of Foreign Affairs of the Russian Federation, including https://www.kdmid.ru/, https://www.mid.ru/, https://zp.midpass.ru/ and their subdomains',
        '领事门户——俄罗斯联邦外交部领事处的官方信息互联网门户，包括https://www.kdmid.ru/、https://www.mid.ru/、https://zp.midpass.ru/及其子域名',
        'Portal consular: el portal oficial de información en internet del Departamento Consular del Ministerio de Relaciones Exteriores de la Federación Rusa, que incluye https://www.kdmid.ru/, https://www.mid.ru/, https://zp.midpass.ru/ y sus subdominios',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;',
        'Depersonalization of personal data — actions that make it impossible to determine without additional information the affiliation of personal data to a specific User or other subject of personal data;',
        '个人数据的去标识化——在不使用额外信息的情况下，无法确定个人数据属于特定用户或其他个人数据主体的行为；',
        'Despersonalización de datos personales: acciones que hacen imposible determinar sin información adicional la pertenencia de los datos personales a un Usuario específico o a otro sujeto de datos personales;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;',
        'Processing of personal data — any action (operation) or a set of actions (operations) performed using automation tools or without using such tools with personal data, including collection, recording, systematization, accumulation, storage, clarification (updating, changing), extraction, use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction of personal data;',
        '个人数据处理——使用自动化工具或不使用这些工具对个人数据进行的任何行为（操作）或一系列行为（操作），包括收集、记录、系统化、积累、存储、澄清（更新、修改）、提取、使用、传输（分发、提供、访问）、去标识化、阻止、删除、销毁个人数据；',
        'El procesamiento de datos personales: cualquier acción (operación) o un conjunto de acciones (operaciones) realizadas utilizando herramientas de automatización o sin usar tales herramientas con datos personales, incluyendo la recopilación, registro, sistematización, acumulación, almacenamiento, clarificación (actualización, cambio), extracción, uso, transferencia (distribución, provisión, acceso), despersonalización, bloqueo, eliminación, destrucción de datos personales;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;',
        'Operator — a state body, municipal body, legal or natural person, independently or jointly with other persons organizing and (or) carrying out the processing of personal data, as well as determining the purposes of processing personal data, the composition of personal data to be processed, actions (operations) performed with personal data;',
        '运营商——国家机关、地方机关、法律或自然人，独立或与其他人共同组织和（或）实施个人数据处理，并确定个人数据处理的目的、需处理的个人数据的组成、对个人数据进行的行为（操作）；',
        'Operador: órgano estatal, órgano municipal, persona jurídica o física que, de forma independiente o conjunta con otras personas, organiza y (o) lleva a cabo el procesamiento de datos personales, así como determina los objetivos del procesamiento de datos personales, la composición de datos personales a procesar, acciones (operaciones) realizadas con datos personales;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Персональные данные — любая информация, относящаяся прямо или косвенно к определённому или определяемому Пользователю Сайта;',
        'Personal data — any information relating directly or indirectly to a specific or identifiable User of the Website;',
        '个人数据——与特定或可识别的网站用户直接或间接相关的任何信息；',
        'Datos personales: cualquier información relacionada directa o indirectamente con un Usuario específico o identificable del Sitio;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Пользователь — это любое физическое или юридическое лицо, которое посещает сайт, регистрируется на нем или использует предлагаемые на нем услуги;',
        'User — any individual or legal entity that visits the Website, registers on it, or uses the services offered on it;',
        '用户——任何访问网站、在其上注册或使用其提供的服务的自然人或法人；',
        'Usuario: cualquier persona física o jurídica que visite el Sitio, se registre en él o utilice los servicios que se ofrecen en él;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Предоставление персональных данных — действия, направленные на раскрытие персональных данных определённому лицу или определённому кругу лиц;',
        'Provision of personal data — actions aimed at disclosing personal data to a specific person or a specific group of persons;',
        '个人数据的提供——旨在向特定个人或特定群体披露个人数据的行为；',
        'Provision de datos personales: acciones dirigidas a revelar datos personales a una persona específica o a un grupo específico de personas;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределённому кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;',
        'Distribution of personal data — any actions aimed at disclosing personal data to an indefinite circle of persons (transfer of personal data) or familiarizing an unlimited circle of persons with personal data, including the disclosure of personal data in the media, placement in information and telecommunication networks, or providing access to personal data in any other way;',
        '个人数据的传播——旨在向不特定群体披露个人数据（个人数据的传输）或向不限制的群体介绍个人数据的任何行为，包括在媒体上披露个人数据、在信息和电信网络中发布或以其他任何方式提供对个人数据的访问；',
        'Distribución de datos personales: cualquier acción destinada a revelar datos personales a un círculo indefinido de personas (transferencia de datos personales) o a familiarizar a un círculo ilimitado de personas con datos personales, incluida la divulgación de datos personales en los medios de comunicación, la colocación en redes de información y telecomunicaciones o la provisión de acceso a datos personales de cualquier otra manera;',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Трансграничная передача персональных данных — передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;',
        'Cross-border transfer of personal data — the transfer of personal data to the territory of a foreign state to a government body of a foreign state, a foreign individual, or a foreign legal entity;',
        '个人数据的跨境传输——将个人数据传输到外国国家的外国政府机构、外国个人或外国法人；',
        'Transferencia transfronteriza de datos personales: la transferencia de datos personales al territorio de un estado extranjero a un órgano del gobierno de un estado extranjero, a un individuo extranjero o a una persona jurídica extranjera;',
      ],
    },
  





    {
      type: 'paragraph',
      text: [
        'Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.',
        'Destruction of personal data — any actions resulting in the irreversible destruction of personal data with no possibility of further restoration of the content of personal data in the personal data information system and (or) resulting in the destruction of material carriers of personal data.',
        '个人数据的销毁——导致个人数据不可逆转地被销毁的任何行为，无法在个人数据的信息系统中进一步恢复个人数据的内容，或导致个人数据的物质载体被销毁的行为。',
        'Destrucción de datos personales: cualquier acción que resulte en la destrucción irreversible de datos personales sin posibilidad de restaurar el contenido de los datos personales en el sistema de información de datos personales y (o) que resulte en la destrucción de los soportes materiales de los datos personales.',
      ],
    },
    {
      type: 'title',
      text: ['3. Какие данные обрабатываются', '3. What Data is Processed'],
    },
    {
      type: 'paragraph',
      text: [
        'Пользователь предоставляет Оператору свои персональные данные путём заполнения специальной формы оформления заявки на оказание услуги или в разделе Личный кабинет Пользователя на Сайте, а также через официальные каналы связи с Оператором (email, телефон, мессенджеры для мгновенного обмена сообщениями WhatsApp или Telegram).',
        "The User provides the Operator with their personal data by filling out a special application form for the provision of services or in the User's Personal Account section on the Website, as well as through official communication channels with the Operator (email, phone, instant messaging applications such as WhatsApp or Telegram).",
        '用户通过填写特别的服务申请表单或在网站的用户个人账户部分，向运营商提供个人数据，以及通过与运营商的官方沟通渠道（电子邮件、电话、即时消息应用程序如WhatsApp或Telegram）。',
        'El Usuario proporciona al Operador sus datos personales al completar un formulario de solicitud especial para la prestación de servicios o en la sección de Cuenta Personal del Usuario en el Sitio, así como a través de canales de comunicación oficiales con el Operador (correo electrónico, teléfono, aplicaciones de mensajería instantánea como WhatsApp o Telegram).',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор может запрашивать и использовать следующие персональные данные Пользователя:',
        'The Operator may request and use the following personal data of the User:',
        '运营商可以请求和使用用户的以下个人数据：',
        'El Operador puede solicitar y utilizar los siguientes datos personales del Usuario:',
      ],
    },
  
 
    {
      type: 'list',
      items: [
        ['имя, фамилия, отчество', 'name, surname, second name', '姓名、姓、父名', 'nombre, apellido, segundo nombre'],
        ['номер телефона', 'phone number', '电话号码', 'número de teléfono'],
        ['адрес электронной почты', 'email address', '电子邮件地址', 'dirección de correo electrónico'],
        ['паспортные данные', 'passport details', '护照信息', 'detalles del pasaporte'],
        ['номера СНИЛС, ИНН', 'SNILS and TIN numbers', 'SNILS 和 TIN 号码', 'números de SNILS e INN'],
        ['дата и место рождения', 'date and place of birth', '出生日期和地点', 'fecha y lugar de nacimiento'],
        [
          'гражданство и обстоятельства его приобретения',
          'citizenship and circumstances of its acquisition',
          '国籍及其获得的情况',
          'nacionalidad y circunstancias de su adquisición',
        ],
        ['биографические сведения', 'biographical information', '个人简历信息', 'información biográfica'],
        [
          'адрес регистрации места жительства',
          'residential registration address',
          '居住登记地址',
          'dirección de registro residencial',
        ],
        [
          'сведения о семейном положении, данные документов ЗАГС',
          'marital status information, civil registry documents data',
          '婚姻状况信息、民事登记文件数据',
          'información sobre estado civil, datos de documentos del registro civil',
        ],
        [
          'сведения о местах работы и учёбы (название организации, её адрес, должность, период)',
          'information about places of work and study (organization name, address, position, period)',
          '工作和学习地点的信息（组织名称、地址、职位、期间）',
          'información sobre lugares de trabajo y estudio (nombre de la organización, dirección, puesto, período)',
        ],
        [
          'сведения об образовании (образовательное учреждение, время обучения, присвоенная квалификация, учёная степень)',
          'education information (educational institution, study period, qualification awarded, academic degree)',
          '教育信息（教育机构、学习时间、授予的资格、学位）',
          'información sobre educación (institución educativa, período de estudio, calificación otorgada, grado académico)',
        ],
        ['сведения о доходах', 'income information', '收入信息', 'información sobre ingresos'],
        ['сведения о банковских счетах', 'bank account information', '银行账户信息', 'información sobre cuentas bancarias'],
        [
          'данные военного билета, удостоверения ветерана боевых действий, приписного удостоверения, справки о прохождении службы',
          'military ID data, combat veteran ID, military service registration certificate, service record certificate',
          '军事身份证数据、作战老兵身份证、兵役登记证明、服役记录证明',
          'datos de identificación militar, identificación de veterano de combate, certificado de registro de servicio militar, certificado de servicio',
        ],
        [
          'данные, которые автоматически передаются в процессе просмотра и при посещении страниц Сайта: IP адрес, информация из cookies, информация о браузере, время доступа, адрес посещаемой страницы, реферер (адрес предыдущей страницы)',
          'data automatically transmitted during the viewing and visiting of Website pages: IP address, cookie information, browser information, access time, page address, referrer (previous page address)',
          '在查看和访问网站页面时自动传输的数据：IP 地址、cookie 信息、浏览器信息、访问时间、页面地址、引荐者（上一个页面地址）',
          'datos que se transmiten automáticamente durante la visualización y visita de las páginas del sitio web: dirección IP, información de cookies, información del navegador, tiempo de acceso, dirección de la página, referente (dirección de la página anterior)',
        ],
      ],
    }
,    
 
 
    {
      type: 'paragraph',
      text: [
        'и аналогичные сведения о детях, супругах Пользователя и других физических лицах, фигурирующих в документах.',
        "and similar information about the User's children, spouses, and other individuals appearing in the documents.",
        '以及有关用户的孩子、配偶和其他出现在文件中的个人的类似信息。',
        'y información similar sobre los hijos, cónyuges y otros individuos del Usuario que aparezcan en los documentos.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.',
        'The above data is collectively referred to as Personal Data in the context of this Policy.',
        '上述数据在本政策的上下文中统称为个人数据。',
        'Los datos mencionados anteriormente se denominan colectivamente Datos Personales en el contexto de esta Política.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор использует персональные данные Пользователя только в том объёме, который ему необходим для оказания запрошенной услуги.',
        "The Operator uses the User's personal data only to the extent necessary to provide the requested service.",
        '运营商仅在提供所请求的服务所需的范围内使用用户的个人数据。',
        'El Operador utiliza los datos personales del Usuario solo en la medida necesaria para proporcionar el servicio solicitado.',
      ],
    },
    {
      type: 'title',
      text: ['4. Цель обработки данных', '4. Purpose of Data Processing'],
    },
    {
      type: 'paragraph',
      text: [
        'В зависимости от услуг, которые Пользователь заказывает через сайт, его персональные данные могут обрабатываться для следующих целей:',
        'Depending on the services that the User orders through the Website, their personal data may be processed for the following purposes:',
        '根据用户通过网站订购的服务，其个人数据可能会被处理为以下目的：',
        'Dependiendo de los servicios que el Usuario solicite a través del Sitio, sus datos personales pueden ser procesados con los siguientes fines:',
      ],
    },
    
  




    {
      type: 'list',
      items: [
        [
          'регистрация и идентификация Пользователя на Сайте, информирование Пользователя о статусе оформленной им заявки на оказание услуг Оператором или подтверждение факта оплаты услуги посредством отправки электронных писем',
          'registration and identification of the User on the Website, informing the User about the status of their application for services with the Operator or confirming the fact of payment for the service by sending emails',
          '用户在网站上的注册和身份识别，通知用户其向运营商申请服务的状态或通过发送电子邮件确认服务付款的事实。',
          'registro e identificación del Usuario en el Sitio, informando al Usuario sobre el estado de su solicitud de servicios al Operador o confirmando el hecho de pago por el servicio mediante el envío de correos electrónicos',
        ],
        [
          'подготовка проектов документов для Пользователя и их последующее размещение на Консульском портале',
          'preparation of draft documents for the User and their subsequent submission to the Consulate portal',
          '为用户准备文件草稿并将其提交至领事馆门户。',
          'preparación de borradores de documentos para el Usuario y su posterior presentación en el portal consular',
        ],
        [
          'запись Пользователя на приём в Консульство',
          'Scheduling an appointment at the Consulate for the User',
          '为用户安排领事馆的预约。',
          'programación de una cita en el Consulado para el Usuario',
        ],
        [
          'предоставление консультационных услуг, в том числе в целях получения Пользователем консульских услуг',
          'provision of consulting services, including those for obtaining consular services by the User',
          '提供咨询服务，包括帮助用户获得领事服务。',
          'prestación de servicios de consultoría, incluidos los destinados a obtener servicios consulares por parte del Usuario',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества Сайта и его содержания. Более подробно о том, как собираются и обрабатываются эти данные описано в Политике использования файлов cookie (https://consul.online/cookie-policy)',
        'Depersonalized Users’ data collected using internet statistics services is used to gather information about Users’ actions on the Website, improve the quality of the Website and its content. More details on how this data is collected and processed are described in the Cookie Use Policy (https://consul.online/cookie-policy).',
        '通过互联网统计服务收集的用户去标识数据用于收集用户在网站上的行为信息，改善网站及其内容的质量。关于如何收集和处理这些数据的更多细节，请参见Cookie使用政策（https://consul.online/cookie-policy）。',
        'Los datos despersonalizados de los Usuarios, recopilados a través de servicios de estadísticas de Internet, se utilizan para recopilar información sobre las acciones de los Usuarios en el Sitio, mejorar la calidad del Sitio y su contenido. Más detalles sobre cómo se recopilan y procesan estos datos se describen en la Política de Uso de Cookies (https://consul.online/cookie-policy).',
      ],
    },
    {
      type: 'title',
      text: ['5. Правовые основания', '5. Legal Basis'],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте, либо через официальные каналы связи с Оператором (email, телефон, мессенджеры для мгновенного обмена сообщениями WhatsApp или Telegram). Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает своё согласие с данной Политикой.',
        "The Operator processes the User's personal data only if it is filled out and/or submitted by the User independently through special forms located on the Website, or through official communication channels with the Operator (email, phone, instant messaging applications such as WhatsApp or Telegram). By filling out the appropriate forms and/or submitting their personal data to the Operator, the User expresses their consent to this Policy.",
        '运营商仅在用户通过位于网站上的特殊表单填写和/或自行提交个人数据时处理用户的个人数据，或通过与运营商的官方沟通渠道（电子邮件、电话、即时消息应用程序如WhatsApp或Telegram）提交。通过填写相应表单和/或向运营商提交其个人数据，用户表示同意本政策。',
        'El Operador procesa los datos personales del Usuario solo si se completan y/o envían de forma independiente por el Usuario a través de formularios especiales ubicados en el Sitio, o a través de canales de comunicación oficiales con el Operador (correo electrónico, teléfono, aplicaciones de mensajería instantánea como WhatsApp o Telegram). Al llenar los formularios correspondientes y/o enviar sus datos personales al Operador, el Usuario expresa su consentimiento a esta Política.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).',
        "The Operator processes depersonalized data about the User if it is allowed in the User's browser settings (cookie storage is enabled and JavaScript technology is used).",
        '如果用户的浏览器设置允许（启用cookie存储和使用JavaScript技术），运营商将处理用户的去标识数据。',
        'El Operador procesa datos despersonalizados sobre el Usuario si está permitido en la configuración del navegador del Usuario (se permite el almacenamiento de cookies y se utiliza tecnología JavaScript).',
      ],
    },
    {
      type: 'title',
      text: ['6. Правила обработки данных', '6. Data Processing Rules'],
    },
    {
      type: 'paragraph',
      text: [
        'Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путём реализации правовых, организационных и технических мер, необходимых для выполнения в полном объёме требований действующего законодательства в области защиты персональных данных.',
        'The security of personal data processed by the Operator is ensured through the implementation of legal, organizational, and technical measures necessary to fully comply with the requirements of the current legislation in the field of personal data protection.',
        '运营商通过实施必要的法律、组织和技术措施来确保处理的个人数据的安全性，以完全遵守现行个人数据保护法律的要求。',
        'La seguridad de los datos personales procesados por el Operador se asegura mediante la implementación de medidas legales, organizativas y técnicas necesarias para cumplir plenamente con los requisitos de la legislación vigente en materia de protección de datos personales.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
        'The Operator ensures the security of personal data and takes all possible measures to prevent unauthorized access to personal data.',
        '运营商确保个人数据的安全，并采取一切可能的措施防止未经授权的访问。',
        'El Operador garantiza la seguridad de los datos personales y toma todas las medidas posibles para evitar el acceso no autorizado a los datos personales.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'В целях достижения целей, описанных выше, Оператор может передавать персональные данные следующим третьим лицам:',
        'For the purposes described above, the Operator may transfer personal data to the following third parties:',
        '为了实现上述目标，运营商可以将个人数据传输给以下第三方：',
        'Con el fin de lograr los objetivos descritos anteriormente, el Operador puede transferir datos personales a los siguientes terceros:',
      ],
    },
    {
      type: 'list',
      items: [
        [
          'Консульство (в целях записи Пользователя на приём или размещения документа Пользователя на Консульском портале)',
          "Consulate (for the purpose of scheduling an appointment for the User or submitting the User's document on the Consulate portal)",
          '领事馆（为用户安排预约或提交用户的文件至领事馆门户）。',
          'Consulado (con el fin de programar una cita para el Usuario o presentar el documento del Usuario en el portal consular)',
        ],
        [
          'Сервис интернет-статистики Google Analytics (в целях сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его содержания)',
          'Internet statistics service Google Analytics (for the purpose of collecting information about User actions on the Website, improving the quality of the Website and its content)',
          '互联网统计服务Google Analytics（用于收集用户在网站上的活动信息，改善网站及其内容的质量）。',
          'Servicio de estadísticas de Internet Google Analytics (con el fin de recopilar información sobre las acciones de los Usuarios en el Sitio, mejorar la calidad del Sitio y su contenido)',
        ],
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Оператор осуществляет передачу данных только в ситуациях, когда это необходимо для выполнения законных обязательств, возложенных на него, и в той мере, в какой такая передача является обязательной.',
        'The Operator transfers data only in the situations where it is necessary to fulfill legal obligations imposed on it, and to the extent that such transfer is mandatory.',
        '运营商仅在为履行其法定义务所需的情况下进行数据传输，并且仅在该等传输是强制性时。',
        'El Operador transfiere datos solo en las situaciones donde es necesario cumplir con las obligaciones legales impuestas sobre él, y en la medida en que tal transferencia sea obligatoria.',
      ],
    },
    {
      type: 'paragraph',
      text: [
        'Срок обработки персональных данных 3 (три) месяца.',
        'The period of personal data processing is 3 (three) months.',
        '个人数据处理的期限为3（三）个月。',
        'El período de procesamiento de datos personales es de 3 (tres) meses.',
      ],
    },
   


    {
      type: 'title',
      text: [
        '7. Трансграничная передача данных',
        '7. Cross-Border Transfer of Personal Data',
        '7. 跨境个人数据传输',
        '7. Transferencia Transfronteriza de Datos Personales',
      ],
    },
    
    {
      type: 'paragraph',
      text: [
        'Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надёжная защита прав субъектов персональных данных.',
        'Before starting the cross-border transfer of personal data, the Operator must ensure that the foreign state to which the personal data is to be transferred provides reliable protection of the rights of personal data subjects.',
        '运营商在开始进行跨境个人数据传输之前，必须确保拟进行个人数据传输的外国政府提供可靠的个人数据主体权利保护。',
        'Antes de iniciar la transferencia transfronteriza de datos personales, el Operador debe asegurarse de que el estado extranjero al que se transferirán los datos personales brinde una protección confiable de los derechos de los sujetos de datos personales.',
      ],
    },
    
    {
      type: 'paragraph',
      text: [
        'Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.',
        'The cross-border transfer of personal data to foreign states that do not meet the above requirements can only be carried out with the written consent of the personal data subject to the cross-border transfer of their personal data and/or the execution of a contract to which the personal data subject is a party.',
        '对不符合上述要求的外国进行的跨境个人数据传输，只有在获得个人数据主体书面同意进行跨境传输或履行其为当事方的合同的情况下才能进行。',
        'La transferencia transfronteriza de datos personales a estados extranjeros que no cumplen con los requisitos anteriores solo se puede llevar a cabo con el consentimiento por escrito del sujeto de datos personales para la transferencia transfronteriza de sus datos personales y/o la ejecución de un contrato en el que el sujeto de datos personales sea parte.',
      ],
    },
    
    { type: 'title', text: ['8. Права Пользователя', '8. User Rights', '8. 用户权利', '8. Derechos del Usuario'] },
    
    {
      type: 'paragraph',
      text: [
        'Пользователь обладает следующими правами в отношении обработки его персональных данных:',
        'The User has the following rights regarding the processing of their personal data:',
        '用户在处理其个人数据时享有以下权利：',
        'El Usuario tiene los siguientes derechos con respecto al procesamiento de sus datos personales:',
      ],
    },
    
    {
      type: 'collapsed',
      title: ['Удаление', 'Removing', '删除', 'Eliminación'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право запросить удаление его персональных данных, если выполняется одно из следующих условий:',
            'The User has the right to request the deletion of their personal data if one of the following conditions is met:',
            '如果满足以下条件之一，用户有权请求删除其个人数据：',
            'El Usuario tiene derecho a solicitar la eliminación de sus datos personales si se cumple una de las siguientes condiciones:',
          ],
        },
        {
          type: 'list',
          items: [
            [
              'данные больше не нужны для целей, для которых они были собраны или обработаны',
              'the data is no longer needed for the purposes for which it was collected or processed',
              '数据不再需要用于收集或处理的目的',
              'los datos ya no son necesarios para los fines para los cuales fueron recopilados o procesados',
            ],
            [
              'Пользователь отзывает своё согласие, если обработка его данных не основана на другом правовом основании',
              'The User withdraws their consent if the processing of their data is not based on another legal basis',
              '如果处理其数据不是基于其他法律依据，用户可以撤回其同意',
              'El Usuario retira su consentimiento si el procesamiento de sus datos no se basa en otra base legal',
            ],
            [
              'Пользователь возражает против обработки, и нет законных оснований для продолжения обработки',
              'The User objects to the processing, and there are no legal grounds for continuing the processing',
              '用户反对处理，并且没有继续处理的合法依据',
              'El Usuario se opone al procesamiento y no hay fundamentos legales para continuar con el procesamiento',
            ],
            [
              'данные были обработаны незаконно',
              'the data was processed unlawfully',
              '数据的处理是非法的',
              'los datos fueron procesados de manera ilegal',
            ],
          ],
        },
      ],
    },
    








    {
      type: 'collapsed',
      title: ['Доступ', 'Access', '访问', 'Acceso'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право получить подтверждение о том, обрабатываются ли персональные данные, касающиеся его, и, если да, доступ к следующей информации:',
            'The User has the right to obtain confirmation as to whether personal data concerning them is being processed, and, if so, access to the following information:',
            '用户有权获得确认其个人数据是否正在处理，如果是，访问以下信息：',
            'El Usuario tiene derecho a obtener confirmación sobre si se están procesando datos personales que le conciernen y, de ser así, a acceder a la siguiente información:',
          ],
        },
        {
          type: 'list',
          items: [
            ['цели обработки', 'the purposes of the processing', '处理的目的', 'los fines del procesamiento'],
            [
              'категории данных, которые обрабатываются',
              'the categories of data being processed',
              '处理的数据类别',
              'las categorías de datos que se están procesando',
            ],
            [
              'получатели или категории получателей, которым данные были переданы или будут переданы',
              'the recipients or categories of recipients to whom the data has been or will be disclosed',
              '接收数据的接收者或接收者类别',
              'los destinatarios o categorías de destinatarios a quienes se han divulgado o se divulgarán los datos',
            ],
            [
              'если возможно, предполагаемый период хранения персональных данных или, если это не возможно, критерии, использованные для определения такого периода',
              'if possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period',
              '如果可能，预计个人数据的存储期限，或者如果不可能，确定该期限所使用的标准',
              'si es posible, el período previsto durante el cual se almacenarán los datos personales o, si no es posible, los criterios utilizados para determinar dicho período',
            ],
          ],
        },
      ],
    },
    
    {
      type: 'collapsed',
      title: ['Исправление', 'Correction', '更正', 'Corrección'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право исправить неточные персональные данные, касающиеся его. Учитывая цели обработки, он также имеет право дополнить неполные персональные данные, в том числе путём предоставления дополнительного заявления. Если это необходимо, Пользователю следует приложить документацию, подтверждающую неточность или неполноту данных, подлежащих обработке.',
            'The User has the right to correct inaccurate personal data concerning them. Considering the purposes of the processing, the User also has the right to supplement incomplete personal data, including by providing an additional statement. If necessary, the user should attach documentation confirming the inaccuracy or incompleteness of the data to be processed.',
            '用户有权更正其个人数据的任何不准确之处。考虑到处理的目的，用户还有权补充不完整的个人数据，包括提供额外的声明。如果必要，用户应附上确认待处理数据的不准确或不完整的文件。',
            'El Usuario tiene derecho a corregir datos personales inexactos que le conciernan. Considerando los fines del procesamiento, el Usuario también tiene derecho a complementar datos personales incompletos, incluida la presentación de una declaración adicional. Si es necesario, el usuario deberá adjuntar documentación que confirme la inexactitud o la incompletud de los datos que se procesarán.',
          ],
        },
      ],
    },
    
    {
      type: 'collapsed',
      title: ['Ограничение', 'Restriction', '限制', 'Restricción'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право запросить ограничение обработки его данных в следующих случаях:',
            'The User has the right to request the restriction of processing of their data in the following cases:',
            '用户有权在以下情况下请求限制其数据的处理：',
            'El Usuario tiene derecho a solicitar la restricción del procesamiento de sus datos en los siguientes casos:',
          ],
        },
        {
          type: 'list',
          items: [
            [
              'пока Оператор проверяет точность персональных данных Пользователя, если Пользователь оспорил их точность',
              "while the Operator verifying the accuracy of the User's personal data if the User has contested their accuracy",
              '在运营商核实用户个人数据的准确性时，如果用户对此准确性提出异议',
              'mientras el Operador verifica la precisión de los datos personales del Usuario si el Usuario ha impugnado su precisión',
            ],
            [
              'в случае, если Пользователь считает обработку незаконной, возражал против удаления данных и, вместо этого, запрашивает ограничение их использования',
              'if the User believes the processing is unlawful, opposed the deletion of data, and instead requests the restriction of their use',
              '如果用户认为处理不合法，反对删除数据，而是请求限制其使用',
              'si el Usuario cree que el procesamiento es ilegal, se opone a la eliminación de datos y, en cambio, solicita la restricción de su uso',
            ],
            [
              'если Оператор больше не нуждается в данных Пользователя для целей обработки, но они необходимы Пользователю для подачи, осуществления или защиты претензий',
              "if the Operator no longer needs the User's data for processing purposes, but they are required by the User for the establishment, exercise, or defense of legal claims",
              '如果运营商不再需要用户的数据进行处理，但用户需要这些数据以提出、行使或保护法律请求',
              'si el Operador ya no necesita los datos del Usuario para fines de procesamiento, pero son necesarios para que el Usuario establezca, ejerza o defienda reclamaciones legales',
            ],
            [
              'пока проверяется, превалируют ли законные основания Оператора над основаниями Пользователя, после возражения против обработки',
              "while it is being verified whether the Operator's legitimate grounds override those of the User, following the User's objection to processing",
              '在用户反对处理后，正在核实运营商的合法理由是否优于用户的理由',
              'mientras se verifica si los motivos legítimos del Operador prevalecen sobre los del Usuario, tras la objeción del Usuario al procesamiento',
            ],
          ],
        },
      ],
    },
    




    {
      type: 'collapsed',
      title: ['Портируемость', 'Portability', '可携带性', 'Portabilidad'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право получить персональные данные, касающиеся его, которые он предоставил Оператору, в структурированном, широко используемом и машиночитаемом формате, и передать их другому ответственному за обработку без препятствий со стороны Оператора, когда обработка основана на согласии и осуществляется с помощью автоматизированных средств. При осуществлении права на портируемость данных Пользователь имеет право на то, чтобы персональные данные передавались непосредственно от одного ответственного за обработку к другому, если это технически возможно. Это право на портируемость не применяется к обработке, которая необходима для выполнения задачи, выполняемой в общественных интересах или при осуществлении публичных полномочий. Право на портируемость не распространяется на данные, которые Оператор вывел из данных, полученных непосредственно из использования предоставленных услуг.',
            'The User has the right to receive personal data concerning them, which the User has provided to the Operator, in a structured, commonly used, and machine-readable format, and to transmit it to another data controller without hindrance from the Operator, where the processing is based on consent and is carried out by automated means. When exercising the right to data portability, the User has the right to have the personal data transmitted directly from one data controller to another, where technically feasible. This right to data portability does not apply to the processing necessary for the performance of a task carried out in the public interest or in the exercise of official authority. The right to data portability does not apply to the data that the Operator has derived from the data obtained directly from the use of the provided services.',
            '用户有权以结构化、普遍使用和机器可读的格式接收其个人数据，并将其传输给另一个数据控制者，而不受运营者的阻碍，前提是处理是基于同意并通过自动化手段进行的。在行使数据可携带权时，用户有权直接将个人数据从一个数据控制者传输到另一个数据控制者，只要技术上可行。此数据可携带权不适用于执行公共利益或行使公共权力所需的处理。可携带权不适用于运营商根据直接使用提供的服务所获得的数据得出的数据。',
            'El Usuario tiene derecho a recibir datos personales que le conciernen, que ha proporcionado al Operador, en un formato estructurado, de uso común y legible por máquina, y a transmitirlos a otro responsable del tratamiento sin obstaculización por parte del Operador, cuando el tratamiento se base en el consentimiento y se realice mediante medios automatizados. Al ejercer el derecho a la portabilidad de datos, el Usuario tiene derecho a que los datos personales se transmitan directamente de un responsable del tratamiento a otro, cuando sea técnicamente posible. Este derecho a la portabilidad de datos no se aplica al tratamiento necesario para la realización de una tarea realizada en interés público o en el ejercicio de una autoridad oficial. El derecho a la portabilidad de datos no se aplica a los datos que el Operador ha derivado de los datos obtenidos directamente de la utilización de los servicios proporcionados.',
          ],
        },
      ],
    },
    
    {
      type: 'collapsed',
      title: ['Возражение', 'Objection', '反对', 'Objeción'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Пользователь имеет право возражать против обработки его персональных данных, включая профилирование, в любое время. Если он решит воспользоваться этим правом, Оператор прекратит обработку его персональных данных, если не сможет доказать законные основания для обработки, которые превалируют над интересами Пользователя, правами и свободами, или для подачи, осуществления или защиты претензий.',
            'The User has the right to object to the processing of their personal data, including profiling, at any time. If the User decides to exercise this right, the Operator will cease processing their personal data unless the Operator can demonstrate compelling legitimate grounds for the processing which override the interests, rights, and freedoms of the User or for the establishment, exercise, or defense of legal claims.',
            '用户有权随时反对处理其个人数据，包括个人资料。如果用户决定行使此权利，运营者将停止处理其个人数据，除非运营者能够证明处理的合法理由优于用户的利益、权利和自由，或用于提出、行使或保护索赔。',
            'El Usuario tiene derecho a oponerse al tratamiento de sus datos personales, incluido el perfilado, en cualquier momento. Si el Usuario decide ejercer este derecho, el Operador cesará el tratamiento de sus datos personales a menos que el Operador pueda demostrar motivos legítimos de peso para el tratamiento que prevalezcan sobre los intereses, derechos y libertades del Usuario o para el establecimiento, ejercicio o defensa de reclamaciones legales.',
          ],
        },
      ],
    },
    
    {
      type: 'collapsed',
      title: ['Автоматизированные решения', 'Automated Decisions', '自动化决策', 'Decisiones Automatizadas'],
      content: [
        {
          type: 'paragraph',
          text: [
            'Субъект данных имеет право не быть объектом решения, основанного исключительно на автоматизированной обработке, включая профилирование, которое имеет юридические последствия или аналогично существенно влияет на него.',
            'The data subject has the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning them or similarly significantly affects them.',
            '数据主体有权不成为仅基于自动化处理的决定的对象，包括个人资料，这对其产生法律效力或类似显著影响。',
            'El sujeto de datos tiene derecho a no ser objeto de una decisión basada únicamente en el procesamiento automatizado, incluido el perfilado, que produzca efectos jurídicos sobre ellos o que los afecte de manera significativa.',
          ],
        },
      ],
    },
    
    {
      type: 'title',
      text: [
        '9. Реализация прав Пользователя',
        '9. User Rights Implementation',
        '9. 用户权利的实施',
        '9. Implementación de los Derechos del Usuario',
      ],
    },
    
    {
      type: 'paragraph',
      text: [
        'Для реализации любого из упомянутых выше прав Пользователь может заполнить форму обратной связи с Оператором, представленную по ссылке, а также получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных.',
        'To exercise any of the aforementioned rights, the User can fill out the feedback form with the Operator, provided via the link, and also receive any explanations on questions regarding the processing of their personal data.',
        '用户可以填写与运营者的反馈表单（通过链接提供），以行使上述任何权利，并就与其个人数据处理相关的问题获得任何解释。',
        'Para ejercer cualquiera de los derechos mencionados anteriormente, el Usuario puede completar el formulario de contacto con el Operador, proporcionado a través del enlace, y también recibir cualquier aclaración sobre preguntas relacionadas con el procesamiento de sus datos personales.',
      ],
    },
    
    {
      type: 'title',
      text: ['10. Заключительные положения', '10. Final Provisions', '10. 最终条款', '10. Disposiciones Finales'],
    },
    
    {
      type: 'paragraph',
      text: [
        'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены её новой версией.',
        'Any changes to the personal data processing policy by the Operator will be reflected in this document. The policy is valid indefinitely until it is replaced with a new version.',
        '本文件将反映运营商对个人数据处理政策的任何更改。该政策有效期无限，直至被新版本取代。',
        'Cualquier cambio en la política de procesamiento de datos personales por parte del Operador se reflejará en este documento. La política es válida indefinidamente hasta que sea reemplazada por una nueva versión.',
      ],
    },
    
    {
      type: 'paragraph',
      text: [
        'Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://consul.online/privacy-policy.',
        'The current version of the Policy is freely available on the Internet at https://consul.online/privacy-policy.',
        '政策的当前版本可以在互联网上自由访问，网址为 https://consul.online/privacy-policy。',
        'La versión actual de la Política está disponible públicamente en Internet en https://consul.online/privacy-policy.',
      ],
    },
    






  
  ],
}
