import * as React from 'react'
import { useContext } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { v4 as uuidv4 } from 'uuid'
import {
  ListItemButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  List,
  Box,
  Typography,
} from '@mui/material'

import logoImage from '../../../images/logo-small.png'

import vocabulary from '../../../vocabulary/vocabulary'
import GlobalContext from '../../../GlobalContext'
import { useNavigate, useLocation } from 'react-router-dom'
import documentBlack from '../../../images/icon/document-black.png'
import buildingImage from '../../../images/icon/building-black.png'
import marriageRingsImage from '../../../images/marriage-black.png'
import courtImage from '../../../images/icon/court-black.png'
import balanceImage from '../../../images/balance-black.png'
import passportImage from '../../../images/passport-black.png'
import stampImage from '../../../images/icon/stamp-black.png'
import { useTheme } from '@mui/material/styles'

import HeaderDesktop from '../Header/HeaderDesktop'
import {
  CalendarCheck,
  ChatText,
  House,
  Question,
  Scales,
  TagSimple,
} from '@phosphor-icons/react'

export default function LeftDrawerDesktopScreen({ children, resolution }) {
  const { langSelected, GlobalActions, state } = useContext(GlobalContext)
  const navigate = useNavigate()
  const theme = useTheme()
  const [openServiceList, setOpenServiceList] = React.useState(false)
  const location = useLocation()

  const handleMenuClose = () => {
    setOpenServiceList(!openServiceList)
  }

  const anchorRef = React.useRef()
  React.useEffect(() => {
    switch (location.pathname) {
      case '/poa':
        GlobalActions.setSubmenuSelected(0)
        GlobalActions.setMenuSelected(2)
        break
      case '/passport':
        GlobalActions.setSubmenuSelected(1)
        GlobalActions.setMenuSelected(2)
        break
      case '/consent':
        GlobalActions.setSubmenuSelected(2)
        GlobalActions.setMenuSelected(2)
        break

      case '/certificates':
        GlobalActions.setSubmenuSelected(3)
        GlobalActions.setMenuSelected(2)
        break

      case '/legal-entities':
        GlobalActions.setSubmenuSelected(4)
        GlobalActions.setMenuSelected(2)
        break
      case '/citizenship':
        GlobalActions.setSubmenuSelected(5)
        GlobalActions.setMenuSelected(2)
        break

      case '/zags':
        GlobalActions.setSubmenuSelected(6)
        GlobalActions.setMenuSelected(2)
        break

      case '/other':
        GlobalActions.setSubmenuSelected(7)
        GlobalActions.setMenuSelected(2)
        break

      case '/consulting':
        GlobalActions.setSubmenuSelected(8)
        GlobalActions.setMenuSelected(2)
        break
      case '/prices':
        GlobalActions.setMenuSelected(6)
        break
      case '/faq':
        GlobalActions.setMenuSelected(5)
        break
      case '/appointment':
        GlobalActions.setMenuSelected(3)
        break
      case '/about':
        GlobalActions.setMenuSelected(1)
        break
      case '/':
        GlobalActions.setMenuSelected(0)
        break

      default:
    }
  }, [location])
  const subMenu = (
    <Box>
      {console.log(vocabulary.listDrawerLeftSubMenu,langSelected)}
      {vocabulary.listDrawerLeftSubMenu[langSelected].map((textSub, index) => {
        return (
          <Box
            key={uuidv4()}
            sx={{
              mb: 2,
            }}
            style={{ display: 'flex', flexDirection: 'column' }}
            onClick={() => {
              switch (index) {
                case 0:
                  GlobalActions.setSubmenuSelected(0)

                  navigate('/poa')

                  break
                case 1:
                  GlobalActions.setSubmenuSelected(1)
                  navigate('/passport')

                  break
                case 2:
                  navigate('/consent')
                  GlobalActions.setSubmenuSelected(2)

                  break
                case 3:
                  GlobalActions.setSubmenuSelected(3)
                  navigate('/certificates')

                  break
                case 4:
                  GlobalActions.setSubmenuSelected(4)
                  navigate('/legal-entities')

                  break
                case 5:
                  GlobalActions.setSubmenuSelected(5)
                  navigate('/citizenship')

                  break

                case 6:
                  GlobalActions.setSubmenuSelected(6)
                  navigate('/zags')

                  break
                case 7:
                  GlobalActions.setSubmenuSelected(7)
                  navigate('/other')

                  break
                case 8:
                  GlobalActions.setSubmenuSelected(8)
                  navigate('/consulting')

                  break

                default:
              }
            }}
          >
            <ListItemButton
              sx={{
                backgroundColor:
                  state.submenuSelected === index
                    ? theme.palette.primary.primaryContainer
                    : null,
                borderRadius: '16px',
              }}
            >
              <ListItemIcon sx={{ width: '24px' }}>
                {index === 0 ? (
                  <img src={documentBlack} alt='logo' width={'24px'} />
                ) : null}
                {index === 1 ? (
                  <img src={passportImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 2 ? (
                  <img src={documentBlack} alt='logo' width={'24px'} />
                ) : null}
                {index === 3 ? (
                  <img src={stampImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 4 ? (
                  <img src={buildingImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 5 ? (
                  <img src={passportImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 6 ? (
                  <img src={marriageRingsImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 7 ? (
                  <img src={courtImage} alt='logo' width={'24px'} />
                ) : null}
                {index === 8 ? (
                  <img src={balanceImage} alt='logo' width={'24px'} />
                ) : null}
              </ListItemIcon>

              <Typography variant='labelLargeShrink'> {textSub}</Typography>
            </ListItemButton>
          </Box>
        )
      })}
    </Box>
  )

  const renderMenu = anchorRef.current ? (
    <Menu
      anchorEl={() => anchorRef.current}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      open={openServiceList}
      onClose={handleMenuClose}
    >
      {subMenu}
    </Menu>
  ) : null

  return (
    <Box style={{ backgroundColor: theme.palette.primary.surface }}>
      <Box sx={{ display: 'flex', backgroundColor: '#F8F9FF' }}>
        <MuiDrawer
          variant='permanent'
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.primary.surface,
              border: 'none',
              maxWidth: state.menuSelected === 2 ? 'none' : '104px',
              overflow: 'hidden',
              width: state.menuSelected === 2 ? 'none' : '104px',
              display: 'flex',
              flexDirection: 'row',
            },
          }}
        >
          <List sx={{ mt: 13, overflow: 'auto' }}>
            {vocabulary.listDrawerLeft[langSelected].map((text, index) => (
              <ListItem
                key={uuidv4() + index}
                sx={{ display: 'block', m: 0, p: 0 }}
              >
                {index !== 2 ? (
                  <ListItemButton
                    sx={{
                      m: 0,
                      mb: 2,
                      p: 0,
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      switch (index) {
                        case 0:
                          GlobalActions.setMenuSelected(0)
                          navigate('/')
                          break
                        case 1:
                          GlobalActions.setMenuSelected(1)
                          navigate('/about')
                          break
                        case 3:
                          GlobalActions.setMenuSelected(3)
                          navigate('/appointment')
                          break
                        case 4:
                          GlobalActions.setMenuSelected(4)

                          navigate(`${window.location.pathname}?open=chat`)

                          break

                        case 5:
                          GlobalActions.setMenuSelected(5)
                          navigate('/faq')
                          break

                        case 6:
                          GlobalActions.setMenuSelected(5)
                          navigate('/prices')
                          break
                        default:
                      }

                      GlobalActions.setSubmenuSelected(false)
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minHeight: 32,
                          maxWidth: 32,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '15px',
                          backgroundColor:
                            state.menuSelected === index
                              ? theme.palette.primary.primaryContainer
                              : null,
                          mb: 1,
                        }}
                      >
                        {index === 0 ? (
                          <House size={24} weight='duotone' />
                        ) : null}
                        {index === 1 ? (
                          <img
                            src={logoImage}
                            alt='logo'
                            width={'20px'}
                            height={'20px'}
                          />
                        ) : null}
                        {index === 2 ? (
                          <Scales size={24} weight='light' />
                        ) : null}
                        {index === 3 ? (
                          <CalendarCheck size={24} weight='light' />
                        ) : null}
                        {index === 4 ? (
                          <ChatText size={24} weight='light' />
                        ) : null}

                        {index === 5 ? (
                          <Question size={24} weight='light' />
                        ) : null}
                        {index === 6 ? (
                          <TagSimple size={24} weight='light' />
                        ) : null}
                      </ListItemIcon>

                      <Typography
                        component='div'
                        variant='labelSmallSemibold'
                        sx={{
                          overflowWrap: 'break-word',
                          maxWidth: '80px',
                          textAlign: 'center',
                        }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    ref={anchorRef}
                    sx={{
                      m: 0,
                      mb: 2,
                      p: 0,
                      justifyContent: 'center',
                    }}
                    component='div'
                    onClick={() => {
                      if (resolution === '1439') {
                        GlobalActions.setMenuSelected(2)
                      } else {
                        setOpenServiceList(!openServiceList)
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <ListItemIcon
                        sx={{
                          minHeight: 32,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '15px',
                          backgroundColor:
                            state.menuSelected === index
                              ? theme.palette.primary.primaryContainer
                              : null,
                        }}
                      >
                        <Scales size={24} weight='light' />
                      </ListItemIcon>

                      <ListItemText>
                        <Typography
                          component='div'
                          variant='labelSmallSemibold'
                          sx={{
                            overflowWrap: 'break-word',
                            maxWidth: '80px',
                            textAlign: 'center',
                          }}
                        >
                          {text}
                        </Typography>
                      </ListItemText>
                    </Box>
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
          {state.menuSelected === 2 && resolution === '1439' ? (
            <Box
              sx={{
                pt: 4,
                pl: 4,
                maxWidth: 300,
                backgroundColor: theme.palette.primary.surfaceContainerLow,
                borderLeft: '1px solid ' + theme.palette.primary.outlineVariant,
              }}
            >
              {subMenu}
            </Box>
          ) : null}
        </MuiDrawer>
        <Box
          sx={{
            ml: state.menuSelected === 2 && resolution === '1439' ? 39 : null,
            backgroundColor: theme.palette.primary.surface,
          }}
        >
          <HeaderDesktop position='fixed' resolution={resolution} />
        </Box>
      </Box>
      {renderMenu}

      {children}
    </Box>
  )
}
