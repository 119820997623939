import { FileText, Buildings, Stamp } from '@phosphor-icons/react'
import passportImage from '../images/passport-black.png'
import marriageRingsImage from '../images/marriage-black.png'
const vocabularyFaq = {
  faq: [
    'Часто задаваемые вопросы', 
    'FAQ', 
    '常见问题', 
    'Preguntas Frecuentes'
  ],
  text1: [
    'Выберите тему:', 
    'Choose the topic:', 
    '选择主题：', 
    'Elige el tema:'
  ],
  typesOfFaq: [
    ['О работе Consul.online', 'About the work of Consul.online', '关于 Consul.online 的工作', 'Sobre el trabajo de Consul.online'],
    ['Доверенности', 'Powers of Attorney', '授权书', 'Poderes']
    ['Загранпаспорт', 'Foreign Passport', '外国护照', 'Pasaporte extranjero'],
    ['Согласия, заявления', 'Consents, Applications', '同意书，申请书', 'Consentimientos, Solicitudes'],
    ['Справки', 'Certificates', '证明', 'Certificados'],
    ['Документы для юр. лиц', 'Documents for Legal Entities', '法人文件', 'Documentos para personas jurídicas'],
    ['Гражданство', 'Citizenship', '国籍', 'Ciudadanía'],
    ['Услуги ЗАГС', 'Registry Office Services', '民事登记处服务', 'Servicios de Registro Civil'],
    ['Легализация документов', 'Legalization of documents', '文件合法化', 'Legalización de documentos'],
  ],
  icons: [
    <FileText size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <FileText size={24} weight='light' />,
    <Stamp size={24} weight='light' />,
    <FileText size={24} weight='light' />,
    <Buildings size={24} weight='light' />,
    <img
      src={passportImage}
      alt='passport'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <img
      src={marriageRingsImage}
      alt='marriage rings'
      width={24}
      style={{ marginTop: '4px' }}
    />,
    <Stamp size={24} weight='light' />,
  ],
};

export default vocabularyFaq
